import { Edit, Print } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Menu } from '@mui/material';
import { Badge, Button, Checkbox, Dropdown, Input, message, Modal, Tooltip } from 'antd';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import smsIcon from '../../../../Assets/sms.png';
import Messages from '../../../../Components/Messages';
import PrintModal from '../PrintModal';
import LPDVIconButtons from '../LPDVIconButtons';
import moment from 'moment';
import LPDPopup from '../../../../Components/LPDPopup';
import { DownOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import styles from "../../EMR.module.css"

const SOAPNotesSection = ({ setShowSOAPNotesSection, scrollToDiagnosisDiv, chiefConcern, patientProfile, initialData = {}, notesData={} }) => {
  const [subjective, setSubjective] = useState(notesData?.subjective || '');
  const [objective, setObjective] = useState(notesData?.objective || '');
  const [assessment, setAssessment] = useState(notesData?.assessment || '');
  const [plan, setPlan] = useState(notesData?.plan || '');
  const [customName, setCustomName] = useState(notesData?.customName || '');
  const [storedChiefConcern, setStoredChiefConcern] = useState(notesData?.chiefConcern || '');
  const [noteId, setNoteId] = useState(initialData?.noteId || notesData?._id || null);
  const [date, setDate] = useState(initialData?.date || notesData?.createdAt || new Date());
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [icd10CMCodes, setICD10CMCodes] = useState([])
  const userTimezone = moment.tz.guess(); 
  const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
  const [orders, setOrders] = useState([]);
  const [newOrderLoading, setNewOrderLoading] = useState(false);
  const [editCustomName, setEditCustomName] = useState(false);

  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchSoapNote = async (noteId) => {
    try {
      const response = await axios.get(`/get-soap-note/${noteId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      if(response.status===200 || response.status===201){
        setSubjective(response.data?.soapNote?.subjective)
        setObjective(response.data?.soapNote?.objective)
        setAssessment(response.data?.soapNote?.assessment)
        setPlan(response.data?.soapNote?.plan)
        setCustomName(response.data?.soapNote?.customName)
      }
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const chiefConcernData = icd10CMCodes.find(item => item.code === chiefConcern) || "";

  useEffect(() => {
    setPatientId(patientProfile._id)
    fetchAllICD10CMCodes()
    if(noteId){
      fetchSoapNote(noteId)
    }
  }, [patientProfile])
  
  useEffect(() => {
    if(date){
      fetchCurrentDateOrders();
    }
  }, [date])

  const handleBlur = async () => {
    try {
      const requestBody = {
        patientId,
        chiefConcern:chiefConcernData,
        subjective,
        objective,
        assessment,
        plan,
        date,
        noteId,
      };

      const response = await axios.post('/soap-note', requestBody);
      console.log('SOAP Note response:', response.data);

      if (!noteId && response.data.soapNote._id) {
        setNoteId(response.data.soapNote._id);
      }
    } catch (error) {
      console.error('Error saving SOAP note:', error);
    }
  };

  const fetchCurrentDateOrders = async() => {
    try{
      const response = await axios.get(
        `/api/patient/${patientProfile?.['Customer ID']}/current-date-order/${date?.split('T')[0]}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
        }
      )
      if(response.status===200 || response.status===201){
        setOrders(response.data?.treatments)
      }

    }
    catch(error){
      console.error("Error fetching current date orders ",error)
    }
  }

  const MessageIcon = ({ patient }) => {
    const unreadCount = patient?.messages?.filter(
      (msg) => msg.readStatus === false
    ).length;

    console.log(unreadCount);
    return (
      <Tooltip
        title={<Messages patient={patient} />}
        trigger="click"
        placement="right"
        overlayInnerStyle={{
          backgroundColor: "#ffffff",
          boxShadow:
            "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",
          padding: "12px",
        }}
        color="#ffffff"
      >
        <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
          <img
            src={smsIcon}
            alt="sms"
            style={{ width: "36px", height: "36px", background: "none" }}
          />
        </Badge>
      </Tooltip>
    );
  };

  const closeModal = () => setPrintModalVisible(false);

const soapNotes={
  subjective,
  objective,
  assessment,
  plan
}

const handleUpdateCustomName= async ()=>{
  try {
    const response = await axios.post(`/update-encounter-custom-name`,{
      noteId,
      patientId,
      customName
    },{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
      }
    });
    setEditCustomName(false)
  } catch (error) {
    console.error("Error saving EMR data:", error);
  }
}


const [showModal,setShowModal] = useState(false)
const [modalType,setModalType] = useState(false)
const [activeTreatmentId,setActiveTreatmentId] = useState(false)

const getActiveTreatment = () => {
  return orders.find((order) => order._id === activeTreatmentId);
};

const createNewTreatment = async (type) => {
  setNewOrderLoading(true)
  let payload={}
  if(notesData){
    payload={
      date:notesData?.createdAt
    }
  }
  else{
    payload={
      date:Date.now()
    }
  }
  try {
    const response = await axios.post(
      `/api/patient/order/new/${patientProfile["Customer ID"]}/${type}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );

    if (response.status === 200 || response.status === 201) {
      message.success(`New ${type.toLowerCase()} created successfully`);
      setOrders((prevState) => {
        return [
          { ...response.data, orderId: response.data._id },
          ...prevState,
        ];
      });
      setNewOrderLoading(false)
    } else {
      throw new Error(`Failed to create new ${type.toLowerCase()}`);
      setNewOrderLoading(false)
    }
  } catch (error) {
    console.error(`Error creating new ${type.toLowerCase()}:`, error);
    message.error(
      `Failed to create new ${type.toLowerCase()}: ${error.message}`
    );
    setNewOrderLoading(false)
  }
};

  return (
    <div className=" w-full h-[80%]">
      <div className='flex flex-col'>
        <div className='flex w-full justify-between'>
          <div className='flex items-center gap-3 '>
          <h1 className="text-2xl font-bold whitespace-nowrap mb-0">SOAP Note {customName &&!editCustomName && `: ${customName}`}</h1>          
          {noteId && editCustomName && <Input.TextArea autoSize={{maxRows:1}} value={customName} onChange={(event)=>setCustomName(event.target.value)} onBlur={handleUpdateCustomName}/>}
          {!editCustomName && <IconButton onClick={()=> setEditCustomName(true)}><EditFilled/></IconButton>}
          </div>
          <MessageIcon patient={patientProfile} />
        </div>
        <div className='flex w-full items-center justify-between'>
          {
            <h1 className="text-lg font-semibold my-3">
              Chief Concern:
              {chiefConcern
                ? `${chiefConcernData.code} - ${chiefConcernData.name}`
                : storedChiefConcern
                  ? `${storedChiefConcern.code} - ${storedChiefConcern.name}`
                  : ""}
            </h1>
          }
          <div className='flex items-center gap-3'>
            {orders.length > 0 ? (
              orders.length === 1 ? (
                <LPDVIconButtons
                  treatment={orders[0]}
                  setShowModal={setShowModal}
                  setModalType={setModalType}
                  setActiveTreatmentId={setActiveTreatmentId}
                />
              ) : (
                <Dropdown
                  overlay={
                    <Menu>
                      {orders.map((order, index) => (
                        <Menu.Item key={index.toString()}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>{index + 1}</span>
                            <LPDVIconButtons
                              treatment={order}
                              setShowModal={setShowModal}
                              setModalType={setModalType}
                              setActiveTreatmentId={setActiveTreatmentId}
                            />
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    Select Order <DownOutlined />
                  </a>
                </Dropdown>
              )
            ) : (
              <div>
                <Button
                  className={`${styles.button}`}
                  icon={<PlusOutlined />}
                  iconPosition="start"
                  onClick={()=>createNewTreatment("Order")}
                  loading={newOrderLoading}
                >
                  Add New Order
                </Button>
              </div>
            )}

            <Tooltip title="Print">
              <Print
                className="cursor-pointer"
                onClick={() => setPrintModalVisible(true)}
                style={{ fontSize: 28, cursor: 'pointer', marginRight: 5 }}
              />
            </Tooltip>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4 '>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Subjective</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Subjective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={subjective}
              onChange={setSubjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Objective</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Objective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={objective}
              onChange={setObjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Assessment</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Assessment Note</h2>
              <div className='flex gap-2'>

                <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">View Template</p>
                <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">Import Past Encounter</p>
                <p className="font-medium whitespace-nowrap  mb-2 text-blue-400 cursor-pointer" onClick={() => scrollToDiagnosisDiv()}>View Diagnosis</p>
                <p className="font-medium whitespace-nowrap  mb-2 text-blue-400 cursor-pointer">View S/I/As</p>
              </div>
            </div>
            <ReactQuill
              theme="snow"
              value={assessment}
              onChange={setAssessment}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Plan</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Plan Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>

            </div>
            <ReactQuill
              theme="snow"
              value={plan}
              onChange={setPlan}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
        </div>
      </div>

      <div>
        <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={patientProfile} soapNotes={soapNotes} formSoapNote={true}/>
      </div>
      {showModal && (
        <LPDPopup
          type={modalType}
          patientName={patientProfile["Full Name"]}
          setAllTreatments={setOrders}
          treatment={getActiveTreatment()}
          showModal={showModal}
          setShowModal={setShowModal}
          setModalType={setModalType}
          // from="emr"
        />
      )}
    </div>
  );
};

export default SOAPNotesSection;
