import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const PastEncountersSection = ({addTab,selectedPatient}) => {

  const [encounters, setEncounters] = useState([])

      const getAllSoapNotes= async ()=>{
        try{
    
          const response= await axios.get(`/get-soap-notes/${selectedPatient._id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
            }
          })
          if(response.data){
            console.log("********** All Soap Notes", response.data)
            setEncounters(response.data.soapNotes)
          }
        }
        catch(error){
          console.log("Error fetching all notes", error)
        }
      }

      useEffect(() => {
        if (selectedPatient) {
          getAllSoapNotes();
        }
      }, [selectedPatient])

  return (
    <div className='px-4 py-2 h-full'>
          <h2 className="text-lg font-semibold mb-2">Past Encounters</h2>
              {encounters.length>0 ?
              <div className=' overflow-auto pr-2 flex flex-col gap-2'>
                {
                   encounters.map((note) => {
                    return (
                      <div className='shadow-sm border-[1px] border-gray-200 px-4 py-3 rounded-xl cursor-pointer hover:bg-gray-100 bg-opacity-50 transition-all duration-300 ' onClick={() => addTab(note.createdAt.split('T')[0],note)}  >
                        <p className="font-medium">{moment(note.createdAt.split('T')[0]).format('MM-DD-YYYY')}</p>
                      </div>
                    )
                  })
                
                }

              </div>:
              <div className='h-auto overflow-auto pr-2 flex flex-col gap-2'>
                  <p className='my-0 mx-auto text-sm'>No Encounters</p>
                </div>
              }
        </div>
  )
}

export default PastEncountersSection