import { DownOutlined, InfoCircleOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Print } from '@mui/icons-material';
import { Fab} from '@mui/material';
import { Button, Dropdown, Form, Input, Menu, message, Select, Space, Spin, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from '../../EMR.module.css';
import PrintModal from '../PrintModal';
import SupplementsModal from '../../../Treatmentview_FAST/Components/SupplementsModal';
import { toast } from 'react-toastify';
import LPDPopup from '../../../../Components/LPDPopup';
import LPDVIconButtons from '../LPDVIconButtons';

const SummarySection = ({ addTab, selectedPatient, selectedChiefConcern, setSelectedChiefConcern, fromSoapNote = false }) => {

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [addCustomMedication, setAddCustomMedication] = useState()
  const [addCustomICDCode, setAddCustomICDCode] = useState(false)
  const [medications, setMedications] = useState([])
  const [icd10CMCodes, setICD10CMCodes] = useState([])
  const [encounters, setEncounters] = useState([])
  const [encountersLoading, setEncountersLoading] = useState(false)
  const [medicationInputValue, setMedicationInputValue] = useState('')
  const [icdName, setICDName] = useState('')
  const [icdCode, setICDCode] = useState('')
  const [frequencyInputValue, setFrequencyInputValue] = useState('')
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [addFieldValue, setAddFieldValue] = useState({
    healthConcerns: false,
    diagnoses: false,
    familyHealthHistory: false,
    pastMedicalHistory: {
      majorEvents: false,
      ongoingMedicalProblems: false,
      preventiveCare: false,
      socialHistory: false,
      nutritionHistory: false,
      developmentHistory: false,
    },
    implantableDevices: false,
    goals: false,
  })

  const toggleSecondaryField = (key, parentKey = null) => {
    if (parentKey) {
      setAddFieldValue((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [key]: !prevState[parentKey][key],
        },
      }));
    } else {
      setAddFieldValue((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }
  };


  const handleSendValue = (parentKey, key) => {
    const mainValue = form.getFieldValue(parentKey ? [parentKey, key] : key) || '';

    const secondaryValue = form.getFieldValue(`${key}Secondary`) || '';

    if (secondaryValue.trim()) {
      if (parentKey) {
        form.setFieldsValue({
          [parentKey]: {
            ...form.getFieldValue(parentKey),
            [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
          },
          [`${key}Secondary`]: '',
        });
      } else {
        form.setFieldsValue({
          [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
          [`${key}Secondary`]: '',
        });
      }
      if (parentKey) {
        handleFieldBlur(`${parentKey}.${key}`);
      } else {
        handleFieldBlur(key);
      }
      setAddFieldValue((prevState) => ({
        ...prevState,
        [parentKey ? parentKey : key]: false,
      }));
    }
  };



  const fetchEMRData = async () => {
    try {
      const response = await axios.get(`/emr/${selectedPatient._id}`)
      if (response.data) {
        form.setFieldsValue(response.data)
      }
      else {
        form.setFieldsValue({})
      }
    } catch (error) {
      console.error('Error fetching EMR data', error);
    }
  }

  const getAllSoapNotes = async () => {
    setEncountersLoading(true)
    try {

      const response = await axios.get(`/get-soap-notes/${selectedPatient._id}/${selectedPatient['Customer ID']}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      })
      if (response.data) {
        setEncounters(response.data.soapNotes)
        setEncountersLoading(false)
      }
    }
    catch (error) {
      console.log("Error fetching all notes", error)
      setEncountersLoading(false)
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      getAllSoapNotes();
      fetchEMRData();
      fetchAllMedications()
      fetchAllICD10CMCodes()
    }
  }, [selectedPatient])

  const handleSelectChiefConcern = (value) => {
    const chiefConcern = "Selected Concern";
    setSelectedChiefConcern(value);
  };  

  const handleBlur = async (values) => {
    try {
      const response = await axios.post('/emr', { patientId: selectedPatient._id, ...values });
      console.log('response of emr api', response.data)
      message.success(response.data.message);
    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error("Failed to save EMR data");
    }
  };

  const handleFieldBlur = (field) => {
    form.validateFields([field]).then(() => {
      const values = form.getFieldsValue();
      handleBlur(values);
    });
  };

  const addMedication = async (medication) => {
    try {
      const response = await axios.post('/emr/add-medication', { patientId: selectedPatient._id, medication });
      console.log('response of emr api', response.data)
      if (response.status === 200) {
        setMedications([...medications, medication])
        message.success(response.data.message);
        setMedicationInputValue('')
        setFrequencyInputValue('')
        addCustomMedication(false)
      }

    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error(error.response.data.message);
    }
  }

  const handleAddMedication = () => {
    if (medicationInputValue.trim() && frequencyInputValue.trim()) {
      const medication = { supplementName: medicationInputValue.trim(), frequency: frequencyInputValue.trim() }
      addMedication(medication)
    }
  }

  const fetchAllMedications = async () => {
    try {
      const response = await axios.get(`/emr/get-medications/${selectedPatient._id}`);
      console.log('response of emr api', response.data)
      setMedications(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const addICD10CMCode = async (icdCode, icdName) => {
    try {
      const response = await axios.post('/add-code', { name: icdName, code: icdCode }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      console.log('response of emr api', response.data)
      if (response.status === 200 || response.status === 201) {
        setICD10CMCodes([...icd10CMCodes, { name: icdName, code: icdCode }])
        message.success(response.data.message);
        setICDName('');
        setICDCode('');
        setAddCustomICDCode(false);
      }

    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error(error.response.data.message);
    }
  }
  const handleAddICD10CMCode = () => {
    if (icdName.trim() && icdCode.trim()) {
      addICD10CMCode(icdCode, icdName)
    }
  }

  const closeModal = () => setPrintModalVisible(false);


  const handleUpdateCustomName= async (noteId)=>{
    const note = encounters.find(note=>note._id===noteId)
    if(note){
    try {
      const response = await axios.post(`/update-encounter-custom-name`,{
        noteId,
        patientId:selectedPatient._id,
        customName:note.customName
      },{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }}
  }
  
  const handleChangeCustomName = (customNameValue, noteId) => {
    setEncounters((prevState) =>
      prevState.map((encounter) => {
        if (noteId === encounter._id) {
          return { ...encounter, customName: customNameValue };
        }
        return encounter; 
      })
    );
  }; 

  const [showModal,setShowModal] = useState(false)
    const [modalType,setModalType] = useState(false)
    const [activeTreatmentId,setActiveTreatmentId] = useState(false)

    const getActiveTreatment = () => {
      const allOrders = encounters.flatMap((note) => note.relatedOrders || []);
      return allOrders.find((order) => order._id === activeTreatmentId);
  };
  return (
    <div>
     {!fromSoapNote&& <Tooltip title="Print">
        <Button icon={<Print />} iconPosition='start' style={{ margin: '15px 0', fontSize: 16, cursor: 'pointer', marginRight: 5 }} className="cursor-pointer"
          onClick={() => setPrintModalVisible(true)}>
          Print Chart
        </Button>
      </Tooltip>
}
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 24 }} 
        wrapperCol={{ span: 24 }}
      >

        <div className={`w-full grid ${fromSoapNote ? 'grid-cols-1' : 'grid-cols-3'} ${fromSoapNote ? 'h-[650px]' : ''} overflow-auto gap-4 bg-transparent`}>
          <div className={`w-full flex  ${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}   justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
            <div className='flex flex-col gap-6 p-3 w-full'>
              <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Health Concerns</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black' />}
                    onClick={() => toggleSecondaryField('healthConcerns')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="healthConcerns"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter health concerns'
                    onBlur={() => handleFieldBlur('healthConcerns')}
                  />
                </Form.Item>
                {addFieldValue['healthConcerns'] && (
                  <SecondaryInput
                    fieldName="healthConcerns"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for Health Concerns"
                  />
                )}
              </div>


              <div
                className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Diagnoses</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black' />}
                    onClick={() => toggleSecondaryField('diagnoses')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="diagnoses"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter diagnoses'
                    onBlur={() => handleFieldBlur('diagnoses')}
                  />
                </Form.Item>
                {addFieldValue['diagnoses'] && (
                  <SecondaryInput
                    fieldName="diagnoses"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for Diagnoses"
                  />
                )}
              </div>
              <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Family Health History</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black' />}
                    onClick={() => toggleSecondaryField('familyHealthHistory')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="familyHealthHistory"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter family health history'
                    onBlur={() => handleFieldBlur('familyHealthHistory')}
                  />
                </Form.Item>
                {addFieldValue['familyHealthHistory'] && (
                  <SecondaryInput
                    fieldName="familyHealthHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for family health history"
                  />
                )}
              </div>

              <div className='flex flex-col w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div className='font-bold text-lg'>Past Medical History</div>
                <Form.Item
                  name={['pastMedicalHistory', 'majorEvents']}>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Major Events</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('majorEvents', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'majorEvents']}
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter major events'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.majorEvents')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.majorEvents && (
                    <SecondaryInput
                      fieldName="majorEvents"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for Major Events"
                    />)}
                </Form.Item>
                <Form.Item
                  name={['pastMedicalHistory', 'ongoingMedicalProblems']}>
                  <div className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Ongoing Medical Problems</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('ongoingMedicalProblems', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'ongoingMedicalProblems']}
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter ongoing medical problem'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.ongoingMedicalProblems')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.ongoingMedicalProblems && (
                    <SecondaryInput
                      fieldName="ongoingMedicalProblems"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for ongoing medical problems"
                    />)}
                </Form.Item>
                <Form.Item
                  name={['pastMedicalHistory', 'preventiveCare']}>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Preventive Care</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('preventiveCare', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'preventiveCare']}
                    className='mb-0'

                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter preventive care'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.preventiveCare')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.preventiveCare && (
                    <SecondaryInput
                      fieldName="preventiveCare"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for preventive care"
                    />)}
                </Form.Item>
                <Form.Item
                  name={['pastMedicalHistory', 'socialHistory']}>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Social History</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('socialHistory', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'socialHistory']}
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter social history'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.socialHistory')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.socialHistory && (
                    <SecondaryInput
                      fieldName="socialHistory"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for social history"
                    />)}
                </Form.Item>
                <Form.Item
                  name={['pastMedicalHistory', 'nutritionHistory']}>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Nutrition History</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('nutritionHistory', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'nutritionHistory']}
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter nutrition history'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.nutritionHistory')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.nutritionHistory && (
                    <SecondaryInput
                      fieldName="nutritionHistory"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for nutrition history"
                    />)}
                </Form.Item>
                <Form.Item
                  name={['pastMedicalHistory', 'developmentHistory']}>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-medium text-base mb-2">Developmental History</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('developmentHistory', 'pastMedicalHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name={['pastMedicalHistory', 'developmentHistory']}
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter developmental history'
                      onBlur={() => handleFieldBlur('pastMedicalHistory.developmentHistory')}
                    />
                  </Form.Item>
                  {addFieldValue['pastMedicalHistory']?.developmentHistory && (
                    <SecondaryInput
                      fieldName="developmentHistory"
                      parentKey="pastMedicalHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for development history"
                    />)}
                </Form.Item>
              </div>

              {!fromSoapNote && <Form.Item
                name="chiefConcern"
                className='w-full border-gray-200 border-[1px] rounded-md p-2'
              >
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Chief Concern</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black'/>}
                    onClick={() => setAddCustomICDCode(!addCustomICDCode)}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                {addCustomICDCode &&
                  <div className='flex gap-2 fadeIn 1s ease-in-out duration-300 my-4'>

                    <Input.TextArea
                      style={{ width: '45%', flexGrow: 1 }}
                      autoSize={{ minRows: 1 }}
                      type='text' placeholder='Enter Code' value={icdCode} onChange={event => setICDCode(event.target.value)} />
                    <Input.TextArea
                      style={{ width: '45%' }}
                      autoSize={{ minRows: 1 }}
                      type='text' placeholder='Enter Name' value={icdName} onChange={event => setICDName(event.target.value)} />
                    <Fab sx={{
                      fontSize: 12,
                      height: 28,
                      width: 28,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 0,

                    }}
                      onClick={handleAddICD10CMCode}
                    >
                      <SendOutlined />
                    </Fab>
                  </div>
                }
                <Select
                  placeholder="Select ICD-10 Code"
                  showSearch
                  optionFilterProp="children"
                  onChange={handleSelectChiefConcern}
                  filterOption={(input, option) =>
                    (option?.children || '').toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {icd10CMCodes.map(({ code, name }) => (
                    <Select.Option key={code} value={code}>
                      {`${code} - ${name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>}
            </div>
          </div>
          <div className={`${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
            <div className='p-3 w-full flex flex-col gap-6'>
              <div className=' flex flex-col gap-4 w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div className='font-bold text-lg'>Allergies</div>

                <Form.Item label={<p className='font-medium text-base'>Drug</p>} name={['allergies', 'drug']} className='mb-0'>
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter drug allergies'
                    onBlur={() => handleFieldBlur(`allergies[drug]`)}
                  />
                </Form.Item>
                <Form.Item label={<p className='font-medium text-base'>Food</p>} name={['allergies', 'food']} className='mb-0'>
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter food allergies'
                    onBlur={() => handleFieldBlur(`allergies[food]`)}
                  />
                </Form.Item>

                <Form.Item label={<p className='font-medium text-base'>Environmental</p>} name={['allergies', 'environmental']} className='mb-0'>
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter environmental allergies'
                    onBlur={() => handleFieldBlur(`allergies[environmental]`)}
                  />
                </Form.Item>
                <Form.Item label={<p className='font-medium text-base'>Medications</p>} name={['allergies', 'medications']} className='mb-0'>
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter medications allergies'
                    onBlur={() => handleFieldBlur(`allergies[medications]`)}
                  />
                </Form.Item>
              </div>
              <div className='flex flex-col w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div className='w-full mb-2 flex items-center justify-between'>
                <p className="font-bold text-lg mb-2">Medications</p>
                <div className='flex items-center gap-2'>
                    <SupplementsModal
                      selectedPatientId={selectedPatient['Customer ID']}
                      selectedPatient={selectedPatient}
                      toastifyToast={toast}
                      from={'emr'}
                      medications={medications}
                      setMedications={setMedications}
                    />
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => setAddCustomMedication(!addCustomMedication)}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                      </div>
                </div>
                {addCustomMedication &&
                <div className='w-full flex-col gap-2'>
                  <div className='flex gap-2 fadeIn 1s ease-in-out duration-300 my-4'>
                    <Input.TextArea
                      style={{ width: '45%' }}
                      autoSize={{ minRows: 1 }}
                      type='text' placeholder='Enter Name' value={medicationInputValue} onChange={event => setMedicationInputValue(event.target.value)} />
                    <Input.TextArea
                      style={{ width: '45%', flexGrow: 1 }}
                      autoSize={{ minRows: 1 }}
                      type='text' placeholder='Enter Frequency' value={frequencyInputValue} onChange={event => setFrequencyInputValue(event.target.value)} />
                    <Fab sx={{
                      fontSize: 12,
                      height: 28,
                      width: 28,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 0,
                    }}
                      onClick={handleAddMedication}
                    >
                      <SendOutlined />
                    </Fab>
                  </div>
                </div>
                }
                <ul className="list-disc ml-5">
                  {medications.length > 0 ? medications?.map((medication) => (
                    <li key={medication.id} >{medication.supplementName} {`(${medication.frequency})`}</li>
                  )) : <p className='text-center my-6'>No Medications</p>
                  }
                </ul>
              </div>


              <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Current Medications</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black' />}
                    onClick={() => toggleSecondaryField('currentMedications')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="currentMedications"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter current medications'
                    onBlur={() => handleFieldBlur('currentMedications')}
                  />
                </Form.Item>
                {addFieldValue['currentMedications'] && (
                  <SecondaryInput
                    fieldName="currentMedications"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for implantable devices"
                  />
                )}
              </div>
              <div className='w-full border-gray-200 border-[1px] rounded-md p-6'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Implantable Devices</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black' />}
                    onClick={() => toggleSecondaryField('implantableDevices')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="implantableDevices"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    placeholder='Enter implantable devices'
                    onBlur={() => handleFieldBlur('implantableDevices')}
                  />
                </Form.Item>
                {addFieldValue['implantableDevices'] && (
                  <SecondaryInput
                    fieldName="implantableDevices"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for implantable devices"
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
            <div className='p-3 w-full flex flex-col gap-6'>

              <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                <div
                  className="w-full mb-2 flex items-center justify-between">
                  <p className="font-bold text-lg">Goals</p>
                  <Button
                    type="text"
                    shape="default"
                    icon={<PlusOutlined className='text-black'/>}
                    onClick={() => toggleSecondaryField('goals')}
                    className="hover:bg-slate-200 text-gray-400 border-none"
                  />
                </div>
                <Form.Item
                  name="goals"
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter goals'
                    onBlur={() => handleFieldBlur('goals')}
                  />
                </Form.Item>
                {addFieldValue['goals'] && (
                  <SecondaryInput
                    fieldName="goals"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for goals"
                  />
                )}
              </div>
              {!fromSoapNote && <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>

                <div className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Encounters</p>
                      <Tooltip color='white' title={
                        <div className='flex items-start flex-col text-black'>
                          <div className='flex items-center gap-3'>
                            <div className="bg-blue-400 p-1 mx-1 rounded-xl"></div>
                            <p>Treatment</p>
                          </div>
                          <div className='flex items-center gap-3'>
                            <div className="bg-orange-400 p-1 mx-1 rounded-xl"></div>
                            <p>Order</p>
                          </div>                          
                      </div>
                      }>
                        <InfoCircleOutlined />
                      </Tooltip>
                  </div>
                
                {
                    encountersLoading? <Spin size="small" style={{margin:'0 auto', width:'100%'}} /> :<div className='max-h-64 overflow-auto pr-2 flex flex-col gap-2'>
                      {
                        encounters.length>0 ? encounters.map((note) => {
                          return (
                            <div className='flex w-full items-center justify-between shadow-sm border-[1px] border-gray-200 px-4 py-3 rounded-xl cursor-pointer hover:bg-gray-100 bg-opacity-50 transition-all duration-300 ' onClick={() => addTab(note.createdAt.split('T')[0], note)}  >
                              <p className="font-medium">{moment(note.createdAt.split('T')[0]).format('MM-DD-YYYY')}</p>
                              
                              {note.relatedOrders.length===1&& <div onClick={(event) => event.stopPropagation()}>
                              <LPDVIconButtons treatment={note.relatedOrders[0]} setShowModal={setShowModal} setModalType={setModalType} setActiveTreatmentId={setActiveTreatmentId}/>
                              </div>}
                              {
                               note.relatedOrders.length > 1 && (
                                <>
                                  <div onClick={(event) => event.stopPropagation()}>

                                  <Dropdown overlay={
                                    
                                    <Menu>
                                    {note.relatedOrders.map((order, index) => (
                                      <Menu.Item key={index.toString()}>                                        
                                        <div 
                                          style={{ display: 'flex', alignItems: 'center' }} 
                                        >
                                          <span style={{ marginRight: '10px' }}>{index + 1}</span>
                                          <LPDVIconButtons
                                            treatment={order}
                                            setShowModal={setShowModal}
                                            setModalType={setModalType}
                                            setActiveTreatmentId={setActiveTreatmentId}
                                          />
                                        </div>
                                      </Menu.Item>
                                    ))}
                                        </Menu>

                                      } trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                          Select Order <DownOutlined />
                                        </a>
                                      </Dropdown>
                                  </div>

                                </>
                              )}
                              {note._id && <Input.TextArea autoSize={{maxRows:1}} value={note.customName} style={{maxWidth:200}} onChange={(event)=>handleChangeCustomName(event.target.value,note._id)} onBlur={()=>handleUpdateCustomName(note._id)} onClick={(event) => event.stopPropagation()} />}
                            </div>
                          )
                        }):
                        <div className='h-auto overflow-auto pr-2 flex flex-col gap-2'>
                          <p className='my-0 mx-auto text-sm'>No Encounters</p>
                        </div>
                      }
                    </div> 
                }
              </div>}
            </div>
          </div>
        </div>
      </Form>
      <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={selectedPatient} encounters={encounters} />
      {showModal && (
        <LPDPopup
          type={modalType}
          patientName={selectedPatient["Full Name"]}
          setAllTreatments={setEncounters}
          treatment={getActiveTreatment()}
          showModal={showModal}
          setShowModal={setShowModal}
          setModalType={setModalType}
          from="emr"
        />
      )}
    </div>
  );
}

const SecondaryInput = ({ fieldName, parentKey, handleSendValue, placeholder }) => {
  return (
    <div className="flex items-center w-full gap-2">
      <Form.Item name={`${fieldName}Secondary`} style={{ flexGrow: 10,marginBottom:0 }}>
        <Input
          name={`${fieldName}Secondary`}
          className='mb-0 mt-1'
          placeholder={placeholder}
        />
      </Form.Item>

      <Fab
        sx={{
          fontSize: 12,
          height: 28,
          width: 28,
          minHeight: 'unset',
          minWidth: 'unset',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleSendValue(parentKey, fieldName)}
      >
        <SendOutlined />
      </Fab>
    </div>

  );
};


export default SummarySection;
