import { Button } from 'antd'
import React from 'react'
import styles from '../../EMR.module.css';


const TemplatesSection = () => {
  return (
<div className='w-full flex flex-col justify-start'>
          <h2 className="text-lg font-semibold mb-2 capitalize">Templates</h2>
          <Button
          className={styles.buttonContained}
          
        >
          Templates
        </Button>
        
    </div>  )
}

export default TemplatesSection