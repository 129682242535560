import { ArrowBack, PlusOneOutlined } from '@mui/icons-material';
import { Button, Spin, Tabs,Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import '../../../../App.css'; // Import the global CSS
import styles from '../../EMR.module.css';
import InnerTabsSection from '../InnerTabsSection';
import SOAPNotesSection from '../SOAPNotesSection';
import SummarySection from '../SummarySection';
import MultipleTxNotes from '../../../../Components/AuditView/Components/MultipleTxNotes'
import {
  PlusOutlined,
  PushpinFilled,
  PushpinOutlined
} from '@ant-design/icons';
import TreatmentNotes from '../../../../Components/TreatmentNotes';
import axios from 'axios';
import UpdatePatient from '../../../../Components/Messages/UpdatePatient';


const { TabPane } = Tabs;

const PatientProfileSection = ({ selectedPatient, setSelectedPatient }) => {
  const targetDivRef = useRef(null);
  const [isPinnedTooltipVisible, setIsPinnedTooltipVisible] = useState(false); 
  const [selectedDate,setSelectedDate]=useState(moment().format("YYYY-MM-DD"))
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(selectedPatient);
  const [mergedPatientAndTreatmentDetails, setMergedPatientAndTreatmentDetails] = useState({});
  const [previousTreatmentsCount, setPreviousTreatmentsCount] = useState(0);
  const userTimezone = moment.tz.guess(); 
  const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
  const [selectedChiefConcern, setSelectedChiefConcern] = useState(null);
  const tooltipRef = useRef(null);

  const handleClickOutside = (event) => {
    const dynamicAllowedElements = document.querySelectorAll(
      '.ant-tooltip, .ant-modal, .some-other-class'
    );
  
    const isOutsideClick = Array.from(dynamicAllowedElements).every(
      (element) => !element.contains(event.target)
    );
  
    if (isOutsideClick) {
      setIsPinnedTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchTreatments= async () => {
    const response = await axios.get(
      `/api/patient/${selectedPatient?.['Customer ID']}/current-date-treatment/${currentDate}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
      }
    )
    return response
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTreatments()
      setPreviousTreatmentsCount(response.data.totalCount)
      setMergedPatientAndTreatmentDetails({...selectedPatient,...response.data.treatments[response.data.treatments.length-1]})
    }
  
    fetchData()
  }, [selectedPatient])

  const scrollToDiv = () => {
    targetDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const initialTabs = [
    { key: '1', tab: 'Summary', closable: false },
    { key: '2', tab: 'Timeline', closable: false },
    { key: '3', tab: 'Documents', closable: false },
    { key: '4', tab: 'Profile', closable: false },
  ];

  const addTab = (newTabName, notesData = '', chiefConcern = '') => {
    if (newTabName.trim() === '') return;

    const newKey = `${Date.now()}`;
    const newTab = { key: newKey, tab: moment(newTabName).format('MM-DD-YYYY'), closable: true, type: 'notes' };

    setTabs((prevTabs) => [...prevTabs, newTab]);
    setActiveKey(newKey);
    setNewTabName('');

    setTabContent((prevTabContent) => ({
      ...prevTabContent,
      [newKey]: createTabContent(newKey, notesData, chiefConcern)
    }));
  };

  const createTabContent = (key, notesData = '', chiefConcern = '') => (
    <div className='flex gap-4 h-[800px] justify-stretch' key={key}>
      <div className='w-[27%] h-full'>
        <InnerTabsSection addTab={addTab} ref={targetDivRef} selectedPatient={selectedPatient}  />
      </div>
      <div className='w-[73%] h-full'>
        <SOAPNotesSection
          scrollToDiagnosisDiv={scrollToDiv}
          patientProfile={selectedPatient}
          notesData={notesData}
          chiefConcern={chiefConcern}
        />
      </div>
    </div>
  );

  // State for tabs
  const [tabs, setTabs] = useState(initialTabs);
  const [activeKey, setActiveKey] = useState(initialTabs[0].key);
  const [newTabName, setNewTabName] = useState('');
  const [tabContent, setTabContent] = useState({
    '1': <SummarySection addTab={addTab} selectedPatient={selectedPatient} selectedChiefConcern={selectedChiefConcern} setSelectedChiefConcern={setSelectedChiefConcern} />,
    '2': <p>Content for Timeline tab</p>,
    '3': <p>Content for Documents tab</p>,
    '4': <UpdatePatient patient={selectedPatient} showSection={true}/>,
    '5': <p>Content for Payment Collection tab</p>,
    '6': <p>Content for Patient Ledger tab</p>,
  });

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const removeTab = (targetKey) => {
    const newTabs = tabs.filter(tab => tab.key !== targetKey);
    const newActiveKey = activeKey === targetKey ? newTabs[0]?.key : activeKey;
    const { [targetKey]: removedContent, ...newTabContent } = tabContent;
    setTabs(newTabs);
    setTabContent(newTabContent);
    setActiveKey(newActiveKey);
  };

  const handleSOAPNoteClick = () => {
    addTab(currentDate, '', selectedChiefConcern);
  };
  return (
    <div className='relative tabs-container w-[95%]'>
      <div className='flex flex-col mb-2 gap-2'>
        <p className='text-4xl font-semibold'>{selectedPatient['Full Name']}</p>
        <div className='w-full flex gap-5'>
          
          {
           selectedPatient?.addresses[0] && <>
             <p className='text-md font-medium'><span className='font-semibold text-black'>Address:</span> {selectedPatient?.addresses[0]?.address1} {selectedPatient?.addresses[0]?.city && `, ${selectedPatient?.addresses[0]?.city}`}{selectedPatient?.addresses[0]?.country && `, ${selectedPatient?.addresses[0]?.country}`}</p>
           </>
         }
          {
           selectedPatient?.sex && <>
             <p className='text-md font-medium'><span className='font-semibold text-black'>Gender:</span> {selectedPatient?.sex}</p>
           </>
         }
          {
            selectedPatient?.phoneNumbers[0]?.number && <>
              <p className='text-md font-medium'><span className='font-semibold text-black'>Phone Numer: </span> {selectedPatient.phoneNumbers[0]?.number}</p>
            </>
          }
           {
            selectedPatient?.DOB && <>
              <p className='text-md font-medium'><span className='font-semibold text-black'>DOB:</span> {moment(selectedPatient.DOB + ''.split('T')).format('MM-DD-YYYY')}</p>
            </>
          }
           {
            selectedPatient?.Email[0]?.email && <>
              <p className='text-md font-medium'><span className='font-semibold text-black'>Email:</span> {selectedPatient?.Email[0]?.email}</p>
            </>
          }
         
        </div>
      </div>
      <Button className={`my-4 ${styles.button}`} icon={<ArrowBack />} onClick={() => setSelectedPatient(null)}>Back</Button>
      <Tabs
        type="editable-card"
        activeKey={activeKey}
        onChange={onTabChange}
        onEdit={(targetKey, action) => action === 'remove' && removeTab(targetKey)}
        hideAdd
        destroyInactiveTabPane
        tabBarExtraContent={
          <div className=' flex items-center gap-2'>
          <Tooltip      
            color="white"
            placement='bottom'
             title={<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px',minWidth:200 }}>
             <Button
               className={styles.button}
               onClick={() => {
                 handleSOAPNoteClick();
               }}
             >
               SOAP Note
             </Button>
             <Button
               className={styles.button}
             >
               Phone/SMS
             </Button>
           </div>}
          >
                <Button className={` ${styles.buttonBlue}`} icon={<PlusOutlined/>} iconPosition='start'>New Encounter</Button>
          </Tooltip>
         { mergedPatientAndTreatmentDetails && Object.keys(mergedPatientAndTreatmentDetails).length > 0 ?
            (
              <div ref={tooltipRef}>
                <Tooltip
                  open={isPinnedTooltipVisible}
                  color="white"
                  overlayInnerStyle={{
                    color: 'black',
                    width: '100%',
                    maxWidth: 'none',
                    whiteSpace: 'normal',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    padding: '10px',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  }}
                  overlayClassName="!max-w-[1200px] !w-auto max-w-none"
                  title={
                    isPinnedTooltipVisible && (
                      <div style={{ width: '100%' }}>
                        {previousTreatmentsCount > 0 ?
                          <TreatmentNotes treatment={mergedPatientAndTreatmentDetails} from='emr' currentDate={currentDate} />
                          :
                          <p className='text-lg p-6'>No treatments scheduled for today</p>
                        }
                      </div>
                    )
                  }
                  placement="left"
                  autoAdjustOverflow={true}
                >
                  {isPinnedTooltipVisible ? <PushpinFilled
                    onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
                    style={{ fontSize: '18px', cursor: 'pointer', marginLeft: 8 }}
                  /> : <PushpinOutlined
                    onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
                    style={{ fontSize: '18px', cursor: 'pointer', marginLeft: 8 }}
                  />}
                </Tooltip>
              </div>
            ) : <Spin size="small" />    }
        </div>
        
        }
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} closable={tab.closable}>
            <div>
              {tabContent[tab.key] || <p>No content available for this tab.</p>}
            </div>
          </TabPane>
        ))}
      
      </Tabs>
      

    </div>
  );
};

export default PatientProfileSection;
