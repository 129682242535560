import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import {
  ArrowBack,
  Assignment,
  Edit,
  EditNote,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { colors, IconButton,Checkbox, Fab, Badge } from "@mui/material";
import { Button, Modal, Form, Input, Select, message, Tooltip } from "antd";
import axios, { all } from "axios";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FormControlLabel from '@mui/material/FormControlLabel';

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Draggable from "react-draggable";
import { Plus } from "lucide-react";
import { red } from "@mui/material/colors";
const dayjs = require('dayjs');

const TasksTab = ({setOverDueTasks,type="expandableTab"}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [assigneesOptions, setAssigneesOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDraggableSection, setShowDraggableSection] = useState(false); 
  const tooltipRef = useRef(null);
  const fabRef = useRef(null);  

  const fetchAllTasks = async () => {
    const token = localStorage.getItem("sessionToken");

    try {
      const response = await axios.get(
        "/all-reminder-tasks",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setAllTasks(response.data);
      } 
    } catch (err) {
      message.error(err.message); 
    }
  };

  useEffect(() => {
    fetchAllTasks();
    fetchAssignees()
    const interval = setInterval(() => {
      fetchAllTasks(); 
    }, 15000);

    return () => clearInterval(interval);
  }, []);


  useEffect(()=>{
    if(type==='popup'){
      const overdueTasks= allTasks.filter(task=>task.due===true || (task.done===false && task.carryOverDay!==null))
      setOverDueTasks(overdueTasks.length)
    }
  },[allTasks])
 

  const fetchAssignees = async () => {
    const assigneesResponse = await axios.get('/get-Assignee-options', {
      headers: {
        'Authorization':`Bearer ${localStorage.getItem('sessionToken')}`
      }
    })
    const assignees = assigneesResponse.data[0];
    const structuredAssigneesOption=assignees?.name.map((assignee,index) => {
        return {key:index, value:assignee,label:assignee}
    })
    setAssigneesOptions(structuredAssigneesOption);
  } 

  
  const filteredDueTasks = allTasks.length>0 && (allTasks.filter(task=>task.due===true || (task.done===false && task.carryOverDay!==null)))
 
  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      fabRef.current &&
      !fabRef.current.contains(event.target)
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);


  return (
    <>
      <div
        className={`${type==='expandableTab' &&  'fixed z-50 bottom-0 right-14 w-1/5'} ${
          (isOpen && type==='expandableTab')  ? "mx-10" : type==='expandableTab'&& "mx-10 my-12"
        } `}
      >
     {type==='expandableTab' && <div
          className={`bg-gray-900 text-white rounded-lg shadow-lg p-3 cursor-pointer transition-transform transform ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center justify-between">
          <p>Tasks</p>{" "}
            <div>
              {filteredDueTasks.length > 0 &&
                <div className="flex items-center bg-red-400 px-4 py-1 rounded-xl">
                  <p className="text-xl font-semibold">{filteredDueTasks.length}</p>
                  <PriorityHighIcon sx={{fontSize:18}} />
              </div>
              }              
            </div>
            {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </div>
        </div>}

        {(isOpen || type==='popup') && (
        <TaskTabBody allTasks={allTasks} setAllTasks={setAllTasks} type={type} assigneesOptions={assigneesOptions}/>
        )}
        {/* {type==='floatingButton'&&
        <>
          <Draggable>
            <div
              style={{
                position: "fixed",
                zIndex: 50,
                bottom: 100,
                right: 20,
              }}
            >
              <Tooltip
                title={
                  <div ref={tooltipRef} className="flex w-full" onMouseDown={(e) => e.stopPropagation()}>
                    <TaskTabBody allTasks={allTasks} setAllTasks={setAllTasks} type={type} assigneesOptions={assigneesOptions} />
                  </div>
                }
                open={showPopup}
                overlayStyle={{
                  minHeight: "320px",
                  height: "fit-content",
                  width: "fit-content",
                  maxWidth: "100%",
                }}
                overlayInnerStyle={{
                  backgroundColor: "white",
                  color: "black",
                  padding: 10,
                }}
                trigger="click"
              >
                <Fab
                  ref={fabRef}
                  type="primary"
                  shape="circle"
                  size="large"
                  onClick={() => {
                    setShowPopup((prev) => !prev);
                  }}
                >
                  <Badge
                    badgeContent={filteredDueTasks.length}
                    color="secondary"
                    overlap="circular"
                    
                    sx={{
                      '& .MuiBadge-badge': {
                        right: -4,
                        top: 2,
                        bgcolor:red['400'],
                        width: '24px',    
                        height: '24px',   
                        fontSize: '0.8rem', 
                      },
                    }}
                    max={99}
                  >
                    <Assignment sx={{ fontSize: 30 }} />
                  </Badge>
                </Fab>

              </Tooltip>

            </div>
          </Draggable>
        </>
        } */}


        {
          type==='floatingButton' &&
          (
            <>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 50,
                    bottom: 100,
                    right: 20,
                  }}
                >
                    <Fab
                      type="primary"
                      shape="circle"
                      size="large"
                      onClick={() => setShowDraggableSection(prev=>!prev)}
                    >
                      <Badge
                        badgeContent={filteredDueTasks.length}
                        color="secondary"
                        overlap="circular"
                        sx={{
                          '& .MuiBadge-badge': {
                            right: -4,
                            top: 2,
                            bgcolor: 'red',
                            width: '24px',
                            height: '24px',
                            fontSize: '0.8rem',
                          },
                        }}
                        max={99}
                      >
                        <Assignment sx={{ fontSize: 30 }} />
                      </Badge>
                    </Fab>
                </div>
        
              {showDraggableSection && (
                <>
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 70,
                    }}
                  >
                    <Draggable>
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '8px',
                          minWidth: '600px',
                          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                        }}
                      >
                        <div className="flex justify-between w-full">
                          <p className="text-lg font-semibold">Tasks</p>
                          <Button
                            type="text"
                            shape="circle"
                            icon={<PlusOutlined className='text-black' style={{ fontSize: 20 }} />}
                            onClick={() => setShowDraggableSection(false)}
                            className="hover:bg-slate-200 text-gray-400 border-none rotate-45"
                          />
                        </div>
                        <TaskTabBody allTasks={allTasks} setAllTasks={setAllTasks} type={type} assigneesOptions={assigneesOptions} />
                      </div>
                    </Draggable>
                  </div>
                </>
              )}
            </>
          )
        }
      </div>
   
    </>
  );
};

const TaskTabBody=({type,allTasks,setAllTasks,assigneesOptions})=>{
  const [viewAll, setViewAll] = useState(false);
  const [frequencyFilter, setFrequencyFilter] = useState(null);
  const [completeAll, setCompleteAll] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [selectedDate, setSelectedDate] = useState(1);
  const [description, setDescription] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [showNoteInput, setShowNoteInput] = useState({});

  const [form] = Form.useForm();

  const frequencyOptions = [
    { key: "everyday", value: "everyday", label: "Every Day" },
    { key: "monthly", value: "monthly", label: "Monthly" },
    { key: "onetime", value: "onetime", label: "One Time Task" },
    { key: "monday", value: 1, label: "Monday" },
    { key: "tuesday", value: 2, label: "Tuesday" },
    { key: "wednesday", value: 3, label: "Wednesday" },
    { key: "thursday", value: 4, label: "Thursday" },
    { key: "friday", value: 5, label: "Friday" },
    { key: "saturday", value: 6, label: "Saturday" },
    { key: "sunday", value: 0, label: "Sunday" },
  ];

  const currentDay = dayjs().day()
  const currentDate = dayjs().format('YYYY-MM-DD')
  const dayOfMonth = dayjs().date();



  
  const getDaysInMonth = (year, month) => {
    return dayjs(`${year}-${month + 1}-01`).daysInMonth();
  };

  const populateDatesForMonth = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const dates = populateDatesForMonth(currentYear, currentMonth);
  

  const toggleNoteInput = (taskId) => {
    setShowNoteInput((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };


  const toggleViewAll = () => {
    setViewAll(!viewAll);
    setFrequencyFilter(null);
  };

  const filteredTasks = allTasks.length>0 && allTasks.filter(
    (task) => !frequencyFilter || task.frequency.includes(frequencyFilter)
  );

  const handleFrequencyFilter = (value) => {
    setFrequencyFilter(value);
  };

  const handleChangeCompleteAll = (event) => {
    if (event.target.checked) {
      updateAllStatus(event.target.checked)
    }
    else {
      setCompleteAll(false)
    }
  }

  const updateAllStatus = async (done) => {
    
    setCompleteAll(true)
    const token = localStorage.getItem("sessionToken");
    const response = await axios.put(
      `/update-alltasks-status/${taskId}`, {
      done,
      signedOffBy: selectedAssignee
    },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 200 || response.status === 201) {
      message.success("Task Updated Successfully");
      setCompleteAll(false)
      
      setAllTasks(response.data.allTasks);
    }
  
};

const updateTaskNote = async (taskId,note) => {
    
  const token = localStorage.getItem("sessionToken");
  const response = await axios.put(
    `/update-task-note/${taskId}`, {
    note
  },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  if (response.status === 200 || response.status === 201) {
    message.success("Task Updated Successfully");
    const updatedDoneTasks = allTasks.map((task) => {
      if (task._id === taskId) {
        return { ...task, note: note }
      }
      else return task
    })
    setAllTasks(updatedDoneTasks);
  }  
};

const handleNoteChange = async (taskId,note) => {
  const updatedDoneTasks = allTasks.map((task) => {
    if (task._id === taskId) {
      return { ...task, note: note }
    }
    else return task
  })
  setAllTasks(updatedDoneTasks);
}
 
const handleSelectDate=(value)=>{
  setSelectedDate(value)
}
const handleSelectFrequency = (value) => {
  if (value.includes('everyday')) {
    setSelectedFrequency(['everyday']);
    form.setFieldsValue({ frequency: 'everyday' });
  }
  if (value.includes('monthly')) {
    setSelectedFrequency(['monthly']);
    form.setFieldsValue({ frequency: 'monthly' });
  }
  if (value.includes('onetime')) {
    setSelectedFrequency(['onetime']);
    form.setFieldsValue({ frequency: 'onetime' });
  }
  else { 
    setSelectedFrequency(value);
    form.setFieldsValue({ frequency: value });
  }

};
const handleSelectAssignee = (value) => {
  setSelectedAssignee(value);
};


const deleteTask = async (taskId) => {
    
  const token = localStorage.getItem("sessionToken");

  const response = await axios.delete(
    `/delete-task/${taskId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  if (response.status === 200 || response.status === 201) {
    message.success("Task Deleted Successfully");
    setAllTasks((prevState) => {
      const filteredTasks = prevState.filter(task => task._id !== taskId)
      return filteredTasks
    });
  }
};

const updateTask = async (taskId) => {
  let payload={}

  if(selectedFrequency.includes('monthly') || selectedFrequency.includes('onetime')){
    payload = {
      description,
      frequency: selectedFrequency,
      date:selectedDate
    };
  }
  else{
    payload = {
      description,
      frequency: selectedFrequency,
    };
  }
  
  const token = localStorage.getItem("sessionToken");

  const response = await axios.put(
    `/update-task/${taskId}`,
    payload,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  if (response.status === 200 || response.status === 201) {
    message.success("Task Updated Successfully");
    const updatedTasks = allTasks.map((task) => {
      if (task._id === response.data.updatedTaskNew._id) {
        return {...response.data.updatedTaskNew}
      }
      else return task
    })
    setAllTasks(updatedTasks);
    handleCancel();
  }
};


const updateStatus = async (taskId, done) => {
    const token = localStorage.getItem("sessionToken");
    const response = await axios.put(
      `/update-task-status/${taskId}`, {
      done,
      signedOffBy: selectedAssignee
    },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 200 || response.status === 201) {
      message.success("Task Updated Successfully");
      const updatedDoneTasks = allTasks.map((task) => {
        if (task._id === taskId) {
          return { ...task, done: done,signedOffBy:selectedAssignee,due:response.data.updatedTask.due }
        }
        else return task
      })
      setAllTasks(updatedDoneTasks);
    }
};


const showModal = () => {
  setIsModalVisible(true);
};

const handleCancel = () => {
  setIsModalVisible(false);
  setDescription("");
  setSelectedFrequency("")
  form.resetFields();
  setIsUpdating(false)
  setTaskId("")
};

const addTask = async () => {
  if (!description || !selectedFrequency) {
    return message.error("Please enter data for all fields")
  }
  let payload={};
  if(selectedFrequency.includes('monthly') || selectedFrequency.includes('onetime')){
    payload = {
      description,
      frequency: selectedFrequency,
      date:selectedDate
    };
  }
  else{
    payload = {
      description,
      frequency: selectedFrequency,
    };
  }
  
  const token = localStorage.getItem("sessionToken");

  const response = await axios.post(
    "/create-reminder-task",
    payload,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  if (response.status === 200 || response.status === 201) {
    message.success("Task Added Successfully");
    setAllTasks([...allTasks,response.data.tasks]);
    handleCancel();
    setDescription("");
  }
};

  return(
    <>
     <div className="bg-white shadow-lg rounded-lg p-2 w-full">
            {viewAll ? (
              <div className="flex items-center mb-2 w-full justify-between">
                <div className="flex items-center justify-between gap-2 w-full">
                  <Button
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    icon={<ArrowBack />}
                    iconPosition="start"
                    onClick={toggleViewAll}
                  >
                    Back
                  </Button>
                  <Select
                    placeholder="Frequency Filter"
                    value={frequencyFilter}
                    onChange={handleFrequencyFilter}
                    allowClear={true}
                    className="w-4/12"
                  >
                    {frequencyOptions.map((option) => (
                      <Select.Option key={option.key} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : (
              <div className="flex items-center mb-2 w-full justify-between">
                  <div className="flex items-center gap-2 flex-wrap">
                    <FormControlLabel control={<Checkbox checked={completeAll} onChange={handleChangeCompleteAll} />} label="Complete All" />

                  <Button
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    icon={<PlusOutlined />}
                    iconPosition="start"
                    onClick={showModal}
                  >
                    Add Task
                  </Button>
                  <Button
                  color="default" variant="filled"
                    className=" hover:!border-gray-700 hover:!text-gray-700 "
                    onClick={toggleViewAll}
                  >
                    View All
                    </Button>
                    <div className="flex flex-col items-center">

                    <Select
                      placeholder="Signed Off By"
                      value={selectedAssignee}
                      onChange={handleSelectAssignee}
                      allowClear={true}
                      style={{
                        minWidth:150
                      }}
                      label="signed off by"
                      mode="multiple"
                      >
                        {assigneesOptions.map((option) => (
                          <Select.Option key={option.key} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                    </Select>
                        </div>
                </div>
              </div>
            )}
            {!viewAll && (
              <ul className={`flex flex-col gap-3 ${type==='popup'?'h-[60vh]':'h-80'} overflow-y-auto`}>
                {allTasks.length>0 && allTasks.filter((task) =>{return (task.frequency.includes("everyday") || (task.frequency.includes(currentDay)&&(task.appear===true)) || task?.carryOverDay!==null || (task?.date===dayOfMonth))}).length >
                0 ? (
                  allTasks
                    .filter((task) => {return (task.frequency.includes("everyday") || (task.frequency.includes(currentDay)&&(task.appear===true)) || task?.carryOverDay!==null || (task?.date===dayOfMonth))})
                    .sort((a, b) => {
                      // Custom sorting logic
                      if (a.due && a.carryOverDay === null) return -1; 
                      if (b.due && b.carryOverDay === null) return 1; 
                      if (a.due && a.carryOverDay !== null) return 1; 
                      if (b.due && b.carryOverDay !== null) return -1; 
                      return 0;
                    })
                    .map((task, index) => (
                      <li
                        key={index}
                        className={`border p-[0.2px] rounded-md shadow-sm  flex flex-col items-center ${
                          (task.due&&task.carryOverDay===null) && "bg-red-200"
                        } ${(!task.done&&task.carryOverDay!==null) && ' border-red-400'}`}
                      >
                        <div className="flex items-center w-full">

                        <div>
                        {task.due&&<PriorityHighIcon sx={{color:`${(task.due&&task.carryOverDay!==null)?colors.red[200]:""}`}}/>}
                          <Checkbox className="flex-grow" checked={task.done} onChange={(event) => {
                            if (event.target.checked) {
                              updateStatus(task._id,true)
                            }
                            else {
                              updateStatus(task._id,false)
                            }
                          }} />
                        </div>
                        <div className="flex-grow relative">
                          <div
                            style={{ transform: "translateY(-50%)" }} 
                          />
                          <p className="text-sm font-medium leading-none relative">{task.description}</p>
                        </div>
                        <div className="flex flex-col">
                          {(task.carryOverDay&&!task.done) &&  <p className="text-xs text-muted-foreground">COD: {moment().day(task?.carryOverDay).format('dddd')}</p> } 
                          {(task.done&& task.signedOffBy.length>0) && <p className="text-xs text-muted-foreground">{task.signedOffBy.join(', ')}</p>}
                        </div>
                          
                          <Button
                            type="text"
                            shape="default"
                            icon={<EditNote className="text-black" />}
                            onClick={() => toggleNoteInput(task._id)}
                            className="hover:bg-slate-200 text-gray-400 border-none mx-2"
                          />
                        </div>
                        {showNoteInput[task._id] &&
                        <div className="py-[1px] pb-[4px] px-[8px] w-full">
                          <Input.TextArea autoSize={{ minRows: 1 }} placeholder="Enter Note" defaultValue={task?.note} onChange={(event) => handleNoteChange(task._id, event.target.value)} onBlur={(event) => updateTaskNote(task._id, event.target.value)} />
                        </div>
                        }
                      </li>
                    ))
                ) : (
                  <div className="h-16 w-full flex items-center justify-center">
                    No Daily Tasks To Show
                  </div>
                )}
              </ul>
            )}
            {viewAll && (
              <ul className={`space-y-2 ${type==='popup'?'h-[60vh]':'h-80'} overflow-y-auto`}>
                {filteredTasks.length > 0 ? (
                  filteredTasks
                    .sort(
                      (a, b) =>
                        (b.due === true ? 1 : 0) - (a.due === true ? 1 : 0)
                    )
                    .map((task, index) => (
                      <li
                        key={index}
                        className={` border p-[0.2px] rounded shadow-sm flex items-center gap-4 ${
                          task.due && "bg-red-200 w-full"
                        }`}
                      >
                        <div>
                          {task.due&&<PriorityHighIcon/>}
                        </div>
                        <p className="text-base font-medium text-gray-600 flex-1">{task.description}</p>
                        <div className="flex items-center gap-1">
                          <IconButton onClick={() => {
                            setDescription(task.description)
                            setSelectedDate(task?.date)
                            setSelectedFrequency(task.frequency)
                            form.setFieldsValue({ frequency: [...task.frequency] }); 
                            form.setFieldsValue({ date: task.reminderDate });  
                            form.setFieldsValue({ day: task.reminderDay });  
                            form.setFieldsValue({ date: task.date });  
                            setIsUpdating(true)
                            setTaskId(task._id)
                            showModal()
                          }}>
                            <Edit />
                          </IconButton>
                          <IconButton onClick={()=>deleteTask(task._id)}>
                            <DeleteFilled style={{color:colors.red["400"]}}/>
                          </IconButton>
                        </div>
                      </li>
                    ))
                ) : (
                  <div className="h-16 w-full flex items-center justify-center">
                    No Tasks To Show
                  </div>
                )}
              </ul>
            )}
          </div>
          <Modal
        title="Add Task"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            className="custom-modal-button"
          >
            Cancel
          </Button>,
            isUpdating ?
              (
                <Button
                    key="submit"
                    type="primary"
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    onClick={()=>updateTask(taskId)}
                  >
                    Update Task
              </Button>
            )
              : (
                <Button
                  key="submit"
                  type="primary"
                  icon={<PlusOutlined />}
                  iconPosition="start"
                  className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                  onClick={addTask}
                >
                  Add Task
                </Button>
            ),
        ]}
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input.TextArea
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />{" "}
          </Form.Item>
          <Form.Item label="Frequency" name="frequency"
          rules={[{ required: true, message: "Please select frequency" }]}>
            <Select
              placeholder="Choose a frequency"
              value={selectedFrequency}
              onChange={handleSelectFrequency}
              allowClear={true}
              mode="multiple"
            >
              {frequencyOptions.map((option) => (
                <Select.Option key={option.key} value={option.value} disabled={(selectedFrequency.includes('everyday') && option.value !== "everyday") || (selectedFrequency.includes('monthly') && option.value !== "monthly") || (selectedFrequency.includes('onetime') && option.value !== "onetime")}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {(selectedFrequency.includes("monthly") || selectedFrequency.includes("onetime")) && (
            <Form.Item label="Date" name="date"
              rules={[{ required: true, message: "Please select date" }]}>
              <Select
                placeholder="Choose a date"
                value={selectedDate}
                onChange={handleSelectDate}
              >
                {dates.map((option) => (
                  <Select.Option key={option.key} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>)
          }
        </Form>
      </Modal>
          </>
  )
}

export default TasksTab;
