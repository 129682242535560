import React, { useState, useEffect, useRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Checkbox,
    Select,
    MenuItem,
    IconButton,
    Tooltip,
    DialogContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesIcon from "@mui/icons-material/Notes";
import styles from "./LPDPopupModal.module.css";
import {
    AddCircleRounded,
    ChevronLeft,
    ChevronRight,
    ContentCopyRounded,
    RemoveCircleRounded,
    ShoppingCartCheckout,
} from "@mui/icons-material";
import MuiButton from "@mui/material/Button";
import { BounceLoader } from "react-spinners";
import { Button, message, Modal, TimePicker } from "antd";
import CustomToast from "../CustomToast";
import { dispensedItemsArray } from "../DispenseModal/dispensedItems";
import TreatmentNotes from "../TreatmentNotes";
import moment from "moment";

const LPDPopup = ({
    // open,
    // procedures,
    // onClose,
    // patientId,
    // patientIdForPreviousTreatment,
    // handleCellChange,
    // handleOpenLabsModal,
    // handleOpenDispenseModal,
    // recordForNotes,
    // selectedDate,
    // clickedTreatmentData,
    // from,
    // treatmentId,
    allTreatments,
    setAllTreatments,
    treatment,
    type,
    setModalType,
    showModal,
    setShowModal,
    patientName,
    from = null,
}) => {
    useEffect(() => {
        console.log("******** treatment", treatment, "*******Type", type)
    }, [type, treatment])
    const [updatedProcedures, setUpdatedProcedures] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalLoader, setModalLoader] = useState(false);
    const [toast, setToast] = useState(null);
    const [editedValues, setEditedValues] = useState({});
    const [isAddingCustomItem, setIsAddingCustomItem] = useState(false);

    const containerRef = useRef(null);
    const toastCount = useRef(0);
    const isTreatmentNoteEditing = useRef(false);

    const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
      isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
    };
    function splitByLastDash(columnName) {
      // Find the index of the last dash
      const lastIndex = columnName.lastIndexOf("-");

      // If there's no dash, or the dash is the first character, return an error or handle it as needed
      if (lastIndex <= 0) {
        console.error(
          "No dash found, or the dash is at the start of the string"
        );
        return columnName;
      }

      // Split the string into two parts
      const itemName = columnName.substring(0, lastIndex);
      const field = columnName.substring(lastIndex + 1);

      return [itemName, field];
    }

    const handleRefreshAndSortItems = () => {
      let sortedItems = [];
      if (type === "Procedures") {
        sortedItems = itemsSortingFunction(
          [...treatment?.userProcedures],
          type.toLowerCase()
        );
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userProcedures: [...sortedItems] , 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            
            setAllTreatments((prevState) =>
              prevState.map((order) => {
                if (order._id === treatment?._id) {
                  return { ...order, userProcedures: [...sortedItems] };
                } else {
                  return order;
                }
              })
            );
        }
      }
      if (type === "Labs") {
        sortedItems = itemsSortingFunction(
          [...treatment.userLabs],
          type.toLowerCase()
        );
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userLabs: [...sortedItems], 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            
            setAllTreatments((prevState) =>
              prevState.map((order) => {
                if (order._id === treatment._id) {
                  return { ...order, userLabs: [...sortedItems] };
                } else {
                  return order; 
                }
              })
            );
        }
      }
      if (type === "Dispensed") {
        sortedItems = itemsSortingFunction(
          [...treatment.userdispensed],
          type.toLowerCase()
        );

        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userdispensed: [...sortedItems] , 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            
            setAllTreatments((prevState) =>
              prevState.map((order) => {
                if (order._id === treatment._id) {
                  return { ...order, userdispensed: [...sortedItems] };
                } else {
                  return order; 
                }
              })
            );
        }

      }
    };

    const handleTimeChange = (index, time) => {
      handleChange(index, "time", time ? time.toISOString() : "");
    };
    const proceduresSortingFunction = (procedures) => {
      const filledProcedures = procedures
        .filter(
          (procedure) =>
            (procedure.selectedField && procedure.selectedField !== "Empty") ||
            procedure.checked
        )
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledProcedures = procedures
        .filter(
          (procedure) =>
            (!procedure.selectedField || procedure.selectedField === "Empty") &&
            !procedure.checked
        )
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledProcedures, ...unfilledProcedures];
    };

    const itemsSortingFunction = (items, itemType) => {
      // Define the dynamic keys for each item type
      let filledCondition, emptyCondition;

      switch (itemType) {
        case "procedures":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        case "labs":
          filledCondition = (item) => item.checked;
          emptyCondition = (item) => !item.checked;
          break;

        case "dispensed":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.quantity > 0;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            item.quantity === 0;
          break;

        case "supplements":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        default:
          return items; // If the item type is not recognized, return the original array.
      }

      const filledItems = items
        .filter(filledCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledItems = items
        .filter(emptyCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledItems, ...unfilledItems];
    };

    const handleCellChange = (
      patientId,
      columnName,
      newValue,
      category,
      itemData,
      review
    ) => {
      if (columnName !== "custom" || columnName !== "note") {
        // Update local state first
        setEditedValues((prevValues) => ({
          ...prevValues,
          [`${patientId}-${columnName}`]: newValue,
        }));
      }
      let payload;
      if (category === "vitals") {
        // Make sure newValue is an array and directly assign it to updates
        payload = {
          category: category,
          updates: newValue, // Ensure this is correctly formatted as an array of vitals
        };
      } else if (category === "patientLabs") {
        payload = {
          category: category,
          updates: {
            checked: newValue,
            type: "item_number",
            value: itemData.item_number,
          },
        };
      } else {
        // Handling for other categories remains the same
        const identifierKey =
          category === "userProcedures" ? "procedure_name" : "name";
        let [itemName, field] = splitByLastDash(columnName);

        if (category === "patientInfo") {
          field = columnName;
        }

        payload = {
          category: category,
          identifier: {
            key: identifierKey,
            value: itemName,
          },
          updates: {
            [field]: newValue,
          },
        };
      }

      // Send the update request using axios
      axios
        .put(
          `/patient/order/update/${treatment._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    useEffect(() => {
      if (type === "Procedures") {
        const proceduresWithOrder = treatment?.userProcedures.map(
          (p, index) => ({
            ...p,
            originalOrder: index,
            showNoteInputRow: false,
          })
        );
        const sortedItems = itemsSortingFunction(
          proceduresWithOrder,
          type.toLowerCase()
        );
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userProcedures: [...sortedItems] 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
              prevState.map((order) => {
                if (order._id === treatment._id) {
                  return { ...order, userProcedures: [...sortedItems] };
                } else {
                  return order;
                }
              })
            );
        }
      } else if (type === "Labs") {
        const labsWithOrder = treatment.userLabs.map((p, index) => ({
          ...p,
          originalOrder: index,
          showNoteInputRow: false,
        }));
        // setUpdatedProcedures(itemsSortingFunction(proceduresWithOrder, type.toLowerCase()));
        const sortedItems = itemsSortingFunction(
          labsWithOrder,
          type.toLowerCase()
        );
        
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userLabs: [...sortedItems]
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return { ...order, userLabs: [...sortedItems] };
                  } else {
                    return order;
                  }
                })
              );
        }
      } else if (type === "Dispensed") {
        const dispensedWithOrder = treatment.userdispensed.map((p, index) => ({
          ...p,
          originalOrder: index,
          showNoteInputRow: false,
        }));
        // setUpdatedProcedures(itemsSortingFunction(proceduresWithOrder, type.toLowerCase()));
        const sortedItems = itemsSortingFunction(
          dispensedWithOrder,
          type.toLowerCase()
        );
        
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userdispensed: [...sortedItems] 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return { ...order, userdispensed: [...sortedItems] };
                  } else {
                    return order; 
                  }
                })
              );
        }
      }
    }, [type]);

    const handleChange = (itemData, itemName, key, value) => {
      console.log(
        "********* handle change triggered",
        itemData,
        itemName,
        key,
        value
      );
      const scrollTop = containerRef.current.scrollTop;
      let newProcedures = [...treatment.userProcedures];
      let newLabs = [...treatment.userLabs];
      let newDispensed = [...treatment.userdispensed];
      let sortedItems = [];
      if (type === "Procedures") {
        newProcedures = treatment.userProcedures.map((procedure) => {
          if (procedure.procedure_name === itemName && key !== "dosages") {
            let updatedProcedure = { ...procedure, [key]: value };
            if (
              (key === "selectedField" && value === "Empty") ||
              value === ""
            ) {
              // Remove the procedure from active procedures
              updatedProcedure = {
                ...updatedProcedure,
                selectedField: "",
                checked: false,
              }; // Uncheck the checkbox
            } else if (key === "selectedField" && value !== "") {
              // Mark the procedure as checked when a dosage is selected
              updatedProcedure.checked = true;
            }
            return updatedProcedure;
          }
          return procedure;
        });
      } else if (type === "Dispensed") {
        newDispensed = treatment.userdispensed.map((item) => {
          if (item.name === itemName) {
            let isFilled = key === "selectedField" ? !!value : item.isFilled;
            let updatedItem = { ...item, [key]: value, isFilled };
            if (key === "selectedField") {
              updatedItem.quantity = value ? 1 : 0;
              updatedItem.checked = value ? true : false;
              handleCellChange(
                treatment._id,
                `${itemName}-quantity`,
                updatedItem.quantity,
                "userdispensed",
                itemData
              );
            }
            return updatedItem;
          }
          return item;
        });
      } else if (type === "Labs") {
        newLabs = treatment.userLabs.map((lab) => {
          if (lab._id === itemData._id) {
            return { ...lab, [key]: value };
          }
          return lab;
        });
      }

      // Sort procedures based on selectedField value and checked status
      sortedItems =
        type === "Labs"
          ? itemsSortingFunction(newLabs, "labs")
          : type === "Procedures"
          ? itemsSortingFunction(newProcedures, "procedures")
          : type === "Dispensed"
          ? itemsSortingFunction(newDispensed, "dispensed")
          : null;
      // setUpdatedProcedures(sortedProcedures);
      
      if(from==='emr'){
        setAllTreatments((prevState) => {
            return prevState.map((note) => {
              const updatedRelatedOrders = note.relatedOrders.map((order) => {
                const updatedOrder = prevState
                  .flatMap((prevNote) => prevNote.relatedOrders) 
                  .find((existingOrder) => existingOrder._id === order._id);
        
                if (updatedOrder) {
                    if (type === "Labs") {
                        return { ...order, userLabs: [...sortedItems] };
                      } else if (type === "Procedures") {
                        return { ...order, userProcedures: [...sortedItems] };
                      } else if (type === "Dispensed") {
                        return { ...order, userdispensed: [...sortedItems] };
                      }
                }
                return order; 
              });
        
              return {
                ...note,
                relatedOrders: updatedRelatedOrders,
              };
            });
          });
    }
    else{
        setAllTreatments((prevState) =>
            prevState.map((order) => {
              if (order._id === treatment._id) {                
                if (type === "Labs") {
                  return { ...order, userLabs: [...sortedItems] };
                } else if (type === "Procedures") {
                  return { ...order, userProcedures: [...sortedItems] };
                } else if (type === "Dispensed") {
                  return { ...order, userdispensed: [...sortedItems] };
                }
              } else {
                return order;
              }
            })
          );
        
    }

      itemData.isNew = false;
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });

      setTimeout(() => {
        if (type === "Procedures") {
          handleCellChange(
            treatment._id,
            `${itemName}-${key}`,
            value,
            "userProcedures",
            itemData,
            treatment.review
          );
        } else if (type === "Labs") {
          handleCellChange(
            treatment._id,
            `${itemName}-${key}`,
            value,
            "patientLabs",
            itemData,
            treatment.review
          );
        } else if (type === "Dispensed") {
          handleCellChange(
            treatment._id,
            `${itemName}-${key}`,
            value,
            "userdispensed",
            itemData
          );
        }
      }, 1000);
    };

    const handleUpdateVitals = () => {
      const defaultVital = {
        HR: 0,
        BP: "",
        T: 0,
        W: 0,
        RR: 0,
        SPO2: 0,
        time: moment.tz("America/Phoenix").toDate(),
      };

      const validatedItems = treatment.vitals.map((item) => {
        return {
          HR: item.HR || defaultVital.HR,
          BP: item.BP || defaultVital.BP,
          T: item.T || defaultVital.T,
          W: item.W || defaultVital.W,
          RR: item.RR || defaultVital.RR,
          SPO2: item.SPO2 || defaultVital.SPO2,
          time: item.time || defaultVital.time,
        };
      });

      handleCellChange(treatment.PatientId, "vitals", validatedItems, "vitals");
      onClose();
    };
    const handleVitalsChange = (index, key, value) => {
      const newItems = treatment.vitals.map((item, i) => {
        if (i === index) {
          return { ...item, [key]: value };
        }
        return item;
      });
      // setAllTreatments(newItems);
      
      if(from==='emr'){
        setAllTreatments((prevState) => {
            return prevState.map((note) => {
              const updatedRelatedOrders = note.relatedOrders.map((order) => {
                const updatedOrder = prevState
                  .flatMap((prevNote) => prevNote.relatedOrders) 
                  .find((existingOrder) => existingOrder._id === order._id);
        
                if (updatedOrder) {
                  return {
                    ...order,
                    vitals: [...newItems] , 
                  };
                }
                return order; 
              });
        
              return {
                ...note,
                relatedOrders: updatedRelatedOrders,
              };
            });
          });
    }
    else{
        setAllTreatments((prevState) =>
            prevState.map((order) => {
              if (order._id === treatment._id) {
                if (type === "Vitals") {
                  return { ...order, vitals: [...newItems] };
                }
              }
              return order;
            })
          );
    }
    };

    const toggleNoteInputRow = (itemName) => {
      if (type === "Procedures") {
        const newProcedures = treatment.userProcedures.map((item) => {
          if (item.procedure_name === itemName) {
            return { ...item, showNoteInputRow: !item.showNoteInputRow };
          }
          return item;
        });
        
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userProcedures: [...newProcedures], 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return { ...order, userProcedures: [...newProcedures] };
                  } else {
                    return order;
                  }
                })
              );
            
        }
      } else if (type === "Dispensed") {
        const newDispensed = treatment.userdispensed.map((item) => {
          if (item.name === itemName) {
            return { ...item, showNoteInputRow: !item.showNoteInputRow };
          }
          return item;
        });
        
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userdispensed: [...newDispensed] , 
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return { ...order, userdispensed: [...newDispensed] };
                  } else {
                    return order;
                  }
                })
              );
            
        }
      }
    };

    useEffect(() => {
        if (isAddingCustomItem && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
            setIsAddingCustomItem(false);
      }
    }, [treatment,isAddingCustomItem]);
    const filteredItems =
      type === "Labs"
        ? treatment?.userLabs.filter((lab) =>
            lab.test_name.toLowerCase().includes(searchTerm)
          )
        : type === "Procedures"
        ? treatment?.userProcedures.filter((procedure) =>
            procedure.procedure_name.toLowerCase().includes(searchTerm)
          )
        : type === "Dispensed"
        ? treatment?.userdispensed.filter((dispense) =>
            dispense.name.toLowerCase().includes(searchTerm)
          )
        : "";

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value.toLowerCase());
    };

    const handleAddEmptyRow = () => {
      if (type === "Procedures" || type === "Dispensed") {
        setIsAddingCustomItem(true);

        let newItem = {};
        if (type === "Procedures") {
          newItem = {
            procedure_name: "",
            selectedField: "",
            note: "",
            isNew: true,
            editItemName: true,
          };
          
          if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userProcedures: [...order.userProcedures, newItem],
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return {
                      ...order,
                      userProcedures: [...order.userProcedures, newItem],
                    };
                  } else {
                    return order;
                  }
                })
              );
        }
        } else if (type === "Dispensed") {
          newItem = {
            name: "",
            selectedField: "",
            quantity: 0,
            isNew: true,
            editItemName: true,
          };
          
          if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        userdispensed: [...order.userdispensed, newItem],
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                  if (order._id === treatment._id) {
                    return {
                      ...order,
                      userdispensed: [...order.userdispensed, newItem],
                    };
                  } else {
                    return order;
                  }
                })
              );
        }
        }
      }
    };

    const getActiveProceduresSummary = () => {
        return updatedProcedures
            .filter(
                (procedure) =>
                    procedure.checked ||
                    (procedure.selectedField && procedure.selectedField !== "Empty")
            )
            .map(
                (procedure) =>
                    `${procedure.procedure_name} (${procedure.selectedField || ""})`
            )
            .join(", ");
    };

    const getCheckedLabsSummary = () => {
        const checkedLabs = treatment.userLabs
            .filter((lab) => lab.checked)
            .map((lab) => lab.test_name)
            .join(", ");

        return checkedLabs;
    };

    const getActiveDispensedItemsSummary = () => {
        const activeItems = treatment.userdispensed.filter((item) => item.isFilled);
        return activeItems
            .map(
                (item) =>
                    `${item.name} (${item.selectedField || "N/A"}) x${item.quantity}`
            )
            .join(", ");
    };
    const handleDeleteProcedure = async (procedureId) => {
        try {

            const response = await axios.delete(
                `/delete/procedureitem/${procedureId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Successfully deleted item, you can now remove it from your UI or refetch the procedures list
            const newProcedures = updatedProcedures.filter(
                (procedure) => procedure._id !== procedureId
            );
            setUpdatedProcedures(newProcedures);
        } catch (error) {
            console.error("Error deleting procedure item:", error);
            // Handle error (e.g., show an error message)
        }
    };

    // const handleNext = () => {
    //     handleOpenDispenseModal(patientId, recordForNotes);

    //     onClose();
    // };

    // const handlePrevious = () => {
    //     handleOpenLabsModal(patientId, recordForNotes);

    //     onClose();
    // };

    const [itemCounters, setItemCounters] = useState({});
    const handleAddItem = (itemName) => {
        const newCounter = (itemCounters[itemName] || 0) + 1;
        const newItemName = `${itemName} ${newCounter}`;

        const selectedItemIndex = treatment.userdispensed.findIndex(
            (item) => item.name === itemName
        );

        if (selectedItemIndex !== -1) {
            const newItem = {
                ...treatment.userdispensed[selectedItemIndex], // Copy the selected item
                name: newItemName, // Update the name with the counter
                selectedField: "", // Reset other fields as needed
                quantity: 0,
                isNew: true,
            };

            // Insert the new item below the selected item
            const newUpdatedItems = [
                ...treatment.userdispensed.slice(0, selectedItemIndex + 1), // Items before the selected item
                newItem, // The new item
                ...treatment.userdispensed.slice(selectedItemIndex + 1), // Items after the selected item
            ];
            
            if(from==='emr'){
                setAllTreatments((prevState) => {
                    return prevState.map((note) => {
                      const updatedRelatedOrders = note.relatedOrders.map((order) => {
                        const updatedOrder = prevState
                          .flatMap((prevNote) => prevNote.relatedOrders) 
                          .find((existingOrder) => existingOrder._id === order._id);
                
                        if (updatedOrder) {
                          return {
                            ...order,
                            userdispensed: [...newUpdatedItems], 
                          };
                        }
                        return order; 
                      });
                
                      return {
                        ...note,
                        relatedOrders: updatedRelatedOrders,
                      };
                    });
                  });
            }
            else{
                setAllTreatments((prevState) =>
                    prevState.map((order) => {
                        if (order._id === treatment._id) {
                            return { ...order, userdispensed: [...newUpdatedItems] };
                        } else {
                            return order;  
                        }
                    })
                );
            }
        }
    };
    const handleRemoveItem = (item) => {
        const newUpdatedItems = treatment.userdispensed.filter(
            (data) => data.name !== item.name
        );

        if (item && item._id) {
            // If the item has an ID, it means it's a saved item, so we need to make a delete request
            axios
                .delete(`/delete/dispenseditem/${item._id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {

                    // Decrement the counter for the corresponding item type
                    const itemName = item.name.split(" ")[0]; // Extracting the item name without counter
                    setItemCounters((prevCounters) => {
                        const newCounters = { ...prevCounters };
                        if (newCounters[itemName]) {
                            newCounters[itemName]--;
                        }
                        return newCounters;
                    });
                    // setUpdatedItems(newUpdatedItems);
                    if(from==='emr'){
                        setAllTreatments((prevState) => {
                            return prevState.map((note) => {
                              const updatedRelatedOrders = note.relatedOrders.map((order) => {
                                const updatedOrder = prevState
                                  .flatMap((prevNote) => prevNote.relatedOrders) 
                                  .find((existingOrder) => existingOrder._id === order._id);
                        
                                if (updatedOrder) {
                                  return {
                                    ...order,
                                    userdispensed: [...newUpdatedItems], 
                                  };
                                }
                                return order; 
                              });
                        
                              return {
                                ...note,
                                relatedOrders: updatedRelatedOrders,
                              };
                            });
                          });
                    }
                    else{
                        setAllTreatments((prevState) =>
                            prevState.map((order) => {
                                if (order._id === treatment._id) {
                                    return { ...order, userdispensed: [...newUpdatedItems] };
                                } else {
                                    return order;  
                                }
                            })
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            // If the item doesn't have an ID, it means it's a newly added item, so just update the state
            // setUpdatedItems(newUpdatedItems);
            if(from==='emr'){
                setAllTreatments((prevState) => {
                    return prevState.map((note) => {
                      const updatedRelatedOrders = note.relatedOrders.map((order) => {
                        const updatedOrder = prevState
                          .flatMap((prevNote) => prevNote.relatedOrders) 
                          .find((existingOrder) => existingOrder._id === order._id);
                
                        if (updatedOrder) {
                          return {
                            ...order,
                            userdispensed: [...newUpdatedItems], 
                          };
                        }
                        return order; 
                      });
                
                      return {
                        ...note,
                        relatedOrders: updatedRelatedOrders,
                      };
                    });
                  });
            }
            else{
                
                setAllTreatments((prevState) =>
                    prevState.map((order) => {
                        if (order._id === treatment._id) {
                            return { ...order, userdispensed: [...newUpdatedItems] };
                        } else {
                            return order; 
                        }
                    })
                );
            }
        }
    };
    // Function to find the previous treatment based on a given date
    const findPreviousTreatment = (treatments) => {
        let previousTreatment = null;
        let currentDate = new Date(treatment.date);
        let found = false;

        while (!found && currentDate) {
            currentDate = getPreviousDate(currentDate);
            previousTreatment = treatments.treatments.find((treatment) => {
                let treatmentDateString = new Date(treatment.date)
                    .toISOString()
                    .split("T")[0];
                return treatmentDateString === currentDate;
            });

            if (previousTreatment) {
                found = true; // Found treatment on previous date
            }
        }

        return previousTreatment;
    };


    const findCurrentTreatment = (treatments) => {
        let currentTreatment = null;
        let currentDate = new Date(treatment.date).toISOString().split("T")[0];
        let found = false;

        currentTreatment = treatments.treatments.find((treatment) => {
            let treatmentDateString = new Date(treatment.date)
                .toISOString()
                .split("T")[0];
            return treatmentDateString === currentDate;
        });

        if (currentTreatment) {
            found = true;
        }

        return currentTreatment;
    };
    const getPreviousDate = (dateString) => {
        const currentDate = new Date(dateString);
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - 1);
        return previousDate.toISOString().split("T")[0];
    };
    const showToast = (variant, message) => {
        setToast({ variant, message });

        setTimeout(() => {
            setToast(null);
        }, 5000);
    };

    const addNewVitalsRow = () => {
        const newVital = {
            BP: "",
            HR: 0,
            RR: 0,
            SPO2: 0,
            T: 0,
            W: 0,
            time: moment.tz("America/Phoenix").toDate(),
        };

        // setUpdatedItems([...treatment.vitals, newVital]);
        if(from==='emr'){
            setAllTreatments((prevState) => {
                return prevState.map((note) => {
                  const updatedRelatedOrders = note.relatedOrders.map((order) => {
                    const updatedOrder = prevState
                      .flatMap((prevNote) => prevNote.relatedOrders) 
                      .find((existingOrder) => existingOrder._id === order._id);
            
                    if (updatedOrder) {
                      return {
                        ...order,
                        vitals: [...treatment.vitals, newVital]
                      };
                    }
                    return order; 
                  });
            
                  return {
                    ...note,
                    relatedOrders: updatedRelatedOrders,
                  };
                });
              });
        }
        else{
            
            setAllTreatments((prevState) =>
                prevState.map((order) => {
                    if (order._id === treatment._id) {                        
                        return { ...order, vitals: [...treatment.vitals, newVital] };
                    } else {
                        return order; 
                    }
                })
            );
        }
    };

    const handleRefreshLabs = async () => {
        // setLabsModalLoader(true);
        try {
            // Fetch new labs data
            const labsResponse = await axios.get(
                `/orderget/${treatment._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                    },
                }
            );
            // Function to sort labs if needed
            const sortLabs = (labs) => {
                // Your sorting logic here. Example:
                return labs.sort((a, b) =>
                    a.checked === b.checked ? 0 : a.checked ? -1 : 1
                );
            };
            // Sort labs here if necessary
            const sortedLabs = sortLabs(labsResponse.data.userLabs);

            // setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
            if(from==='emr'){
                setAllTreatments((prevState) => {
                    return prevState.map((note) => {
                      const updatedRelatedOrders = note.relatedOrders.map((order) => {
                        const updatedOrder = prevState
                          .flatMap((prevNote) => prevNote.relatedOrders) 
                          .find((existingOrder) => existingOrder._id === order._id);
                
                        if (updatedOrder) {
                          return {
                            ...order,
                            userLabs: [...sortedLabs] , 
                          };
                        }
                        return order; 
                      });
                
                      return {
                        ...note,
                        relatedOrders: updatedRelatedOrders,
                      };
                    });
                  });
            }
            else{
                
                setAllTreatments((prevState) =>
                    prevState.map((order) => {
                        if (order._id === treatment._id) {
                            return { ...order, userLabs: [...sortedLabs] };
                        } else {
                            return order;  
                        }
                    })
                );
            }

        } catch (error) {
            // setLoading(false);
            console.error("Error refreshing labs and tubes:", error);
        }
    };
    const handleCopyLabsFromPreviousTreatment = async () => {
        // setLabsModalLoader(true);
        try {
            // Fetch previous treatments
            const previousTreatmentsResponse = await axios.get(
                `/api/patient/${treatment.PatientId}/last-treatments`
            );

            if (previousTreatmentsResponse.data.totalCount <= 0) {
                // setLabsModalLoader(false);
                showToast(
                    "error",
                    "There are no labs from the previous treatment to copy from"
                );
                return;
            }

            // Filter to keep only treatments of type "Treatment"
            const treatments = previousTreatmentsResponse.data.treatments.filter(
                (treatment) => treatment.type === "Treatment"
            );

            if (treatments.length <= 0) {
                // setLabsModalLoader(false);
                showToast(
                    "error",
                    "There are no treatments of the required type to copy labs from"
                );
                return;
            }

            const treatment = findPreviousTreatment({ treatments: treatments });
            const currentTreatment = findCurrentTreatment({
                treatments: treatments,
            });

            if (currentTreatment.review == true) {
                showToast(
                    "error",
                    "The status of this treatment is reviewed, so labs can't be copied"
                );
                // setLabsModalLoader(false);
                return;
            }
            const previousLabs = treatment.userLabs.filter(
                (previouslab) => previouslab.checked === true
            );
            if (previousLabs.length <= 0) {
                // setLabsModalLoader(false);
                showToast(
                    "error",
                    "There are no labs from the previous treatment to copy from"
                );
                return;
            }

            const updatePromises = previousLabs.map((previouslab) => {
                const payload = {
                    category: "patientLabs",
                    updates: {
                        checked: true,
                        value: previouslab.item_number && `${previouslab.item_number}`,
                        type: previouslab.item_number && "item_number",
                    },
                };

                return axios.put(
                    `/patient/order/update/${treatment._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "sessionToken"
                            )}`,
                        },
                    }
                );
            });

            Promise.all(updatePromises)
                .then((responses) => {
                    const updatedLabs = responses
                        .flatMap((response) => response.data.userLabs)
                        .filter((lab) => lab.checked === true);

                    handleRefreshLabs();
                    // setLabsModalLoader(false);

                    showToast("success", "Labs Copied Successfully");
                })
                .catch((error) => {
                    // setLabsModalLoader(false);

                    showToast("error", "Failed to copy labs from previous treatment");
                });
        } catch (error) {
            showToast("error", "Error fetching previous treatments:");
            // setLabsModalLoader(false);

            showToast("error", "Failed to fetch previous treatments");
        }
    };
    const handleCopyProceduresFromPreviousTreatment = async () => {
        setModalLoader(true);
        try {
            // Fetch previous treatments
            const previousTreatmentsResponse = await axios.get(
                `/api/patient/${treatment.PatientId}/last-treatments`
            );

            // Filter to keep only treatments of type "Treatment"
            const treatments = previousTreatmentsResponse.data.treatments.filter(
                (treatment) => treatment.type === "Treatment"
            );

            if (treatments.length <= 0) {
                setModalLoader(false);
                showToast(
                    "error",
                    "There are no procedures from the previous treatment to copy from"
                );
                return;
            }

            // Assume findPreviousTreatment and findCurrentTreatment take a list of treatments and return a single treatment
            const treatment = findPreviousTreatment({ treatments: treatments });
            const currentTreatment = findCurrentTreatment({ treatments: treatments });

            if (currentTreatment.review == true) {
                showToast(
                    "error",
                    "The status of this treatment is reviewed, so labs can't be copied"
                );
                setModalLoader(false);
                return;
            }
            const previousProcedures = treatment.userProcedures.filter(
                (previousProcedure) =>
                    previousProcedure.checked === true ||
                    (previousProcedure.selectedField !== undefined &&
                        previousProcedure.selectedField !== null &&
                        previousProcedure.selectedField !== "")
            );
            if (previousProcedures.length <= 0) {
                setModalLoader(false);
                showToast(
                    "error",
                    "There are no procedures from the previous treatment to copy from"
                );
                return;
            }
            previousProcedures.forEach((procedure) => {
                if ("note" in procedure) {
                    handleChange(
                        procedure,
                        procedure.procedure_name,
                        "note",
                        procedure.note
                    );
                }
                if ("customDosage" in procedure) {
                    handleChange(
                        procedure,
                        procedure.procedure_name,
                        "customDosage",
                        procedure.customDosage
                    );
                }

                if (procedure.checked == true && !procedure.selectedField) {
                    handleChange(procedure, procedure.procedure_name, "checked", true);
                }
                if ("selectedField" in procedure) {
                    procedure.checked = false;
                    handleChange(
                        procedure,
                        procedure.procedure_name,
                        "selectedField",
                        procedure.selectedField
                    );
                }
            });

            setTimeout(async () => {
                setModalLoader(true);
                const response = await axios.get(
                    `/orderget/${treatment._id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                        },
                    }
                );

                const fetchedProcedures = response.data.userProcedures;

                const updatedProceduresCopy = [...updatedProcedures];

                previousProcedures.forEach((procedure) => {
                    const existingProcedureIndex = updatedProceduresCopy.findIndex(
                        (p) => p.procedure_name === procedure.procedure_name
                    );

                    if (existingProcedureIndex !== -1) {
                        if ("note" in procedure) {
                            updatedProceduresCopy[existingProcedureIndex].note =
                                procedure.note;
                        }
                        if ("customDosage" in procedure) {
                            updatedProceduresCopy[existingProcedureIndex].customDosage =
                                procedure.customDosage;
                        }
                        if (procedure.checked && !procedure.selectedField) {
                            updatedProceduresCopy[existingProcedureIndex].checked = true;
                        }
                        if ("selectedField" in procedure) {
                            updatedProceduresCopy[existingProcedureIndex].selectedField =
                                procedure.selectedField;
                            updatedProceduresCopy[existingProcedureIndex].checked = false;
                        }
                    } else {
                        updatedProceduresCopy.push({
                            ...procedure,
                            isNew: true,
                            showNoteInputRow: false,
                        });
                    }
                });
                const sortedProcedures = proceduresSortingFunction([
                    ...updatedProceduresCopy,
                ]);

                setUpdatedProcedures(sortedProcedures);
                setModalLoader(false);

                setModalLoader(false);
            }, 2000);

            setModalLoader(true);

            showToast(
                "success",
                "Successfully copied procedures from previous treatments"
            );
        } catch (error) {
            setModalLoader(false);

            showToast("error", "Failed to fetch previous treatments");
        }
    };

    const handleCopyDispensedItemsFromPreviousTreatment = async () => {
        setModalLoader(true);
        try {
            // Fetch previous treatments
            const previousTreatmentsResponse = await axios.get(
                `/api/patient/${treatment._id}/last-treatments`
            );

            if (previousTreatmentsResponse.data.totalCount <= 0) {
                setModalLoader(false);
                showToast(
                    "error",
                    "There are no dispensed items from the previous treatment to copy from"
                );
                return;
            }

            // Filter to keep only treatments of type "Treatment"
            const treatments = previousTreatmentsResponse.data.treatments.filter(
                (treatment) => treatment.type === "Treatment"
            );

            if (treatments.length <= 0) {
                setModalLoader(false);
                showToast(
                    "error",
                    "There are no treatments of the required type to copy from"
                );
                return;
            }

            const treatment = findPreviousTreatment({ treatments: treatments });
            const currentTreatment = findCurrentTreatment({ treatments: treatments });

            if (currentTreatment.review == true) {
                showToast(
                    "error",
                    "The status of this treatment is reviewed, so labs can't be copied"
                );
                setModalLoader(false);
                return;
            }

            const previousDispensedItems = treatment.userdispensed.filter(
                (previousDispensedItem) =>
                    previousDispensedItem.quantity >= 1 ||
                    (previousDispensedItem.selectedField !== undefined &&
                        previousDispensedItem.selectedField !== null &&
                        previousDispensedItem.selectedField !== "")
            );

            if (previousDispensedItems.length <= 0) {
                setModalLoader(false);
                showToast(
                    "error",
                    "There are no dispensed items from the previous treatment to copy from"
                );
                return;
            }

            previousDispensedItems.forEach((item) => {
                if ("quantity" in item) {
                    handleChange(item, item.name, "quantity", item.quantity);
                }
                if ("name" in item) {
                    handleChange(item, item.name, "name", item.name);
                }
                if ("note" in item) {
                    handleChange(item, item.name, "note", item.note);
                }
                if ("selectedField" in item) {
                    handleChange(item, item.name, "selectedField", item.selectedField);
                }
            });
            const updatedItemsCopy = treatment.userdispensed.map((item) => {
                const foundItem = previousDispensedItems.find(
                    (prevItem) => prevItem.name === item.name
                );
                if (foundItem) {
                    return {
                        ...item,
                        quantity: foundItem.quantity,
                        selectedField: foundItem.selectedField,
                        note: foundItem.note,
                    };
                }
                return item;
            });

            const itemsWithDetails = updatedItemsCopy.map((item, index) => ({
                ...item,
                showInput: false,
                isFilled: !!item.selectedField,
                originalOrder: index,
            }));
            const sortedItems = itemsSortingFunction(itemsWithDetails, type.toLowerCase());

            if(from==='emr'){
                setAllTreatments((prevState) => {
                    return prevState.map((note) => {
                      const updatedRelatedOrders = note.relatedOrders.map((order) => {
                        const updatedOrder = prevState
                          .flatMap((prevNote) => prevNote.relatedOrders) 
                          .find((existingOrder) => existingOrder._id === order._id);
                
                        if (updatedOrder) {
                          return {
                            ...order,
                            userdispensed: [...sortedItems] , 
                          };
                        }
                        return order; 
                      });
                
                      return {
                        ...note,
                        relatedOrders: updatedRelatedOrders,
                      };
                    });
                  });
            }
            else{
                
                setAllTreatments((prevState) =>
                    prevState.map((order) => {
                        if (order._id === treatment._id) {
                            return { ...order, userdispensed: [...sortedItems] };
                        } else {
                            return order;  
                        }
                    })
                );
            }

            setModalLoader(true);

            setModalLoader(false);

            showToast(
                "success",
                "Successfully copied dispensed items from previous treatments"
            );
        } catch (error) {
            console.error("Error fetching previous treatments:", error);
            showToast("error", "Failed to fetch previous treatments");
            setModalLoader(false);
        }
    };

    const handleCopyVitalsFromPreviousTreatment = async () => {
        setModalLoader(true);
        try {
            const previousTreatments = await axios.get(
                `/api/patient/${treatment._id}/last-treatments`
            );

            if (previousTreatments.data.totalCount <= 0) {
                showToast(
                    "error",
                    "There are no vitals from the previous treatment to copy from"
                );
                return;
            }

            const treatment = findPreviousTreatment(previousTreatments.data);
            const currentTreatment = findCurrentTreatment(previousTreatments.data);

            if (currentTreatment.review == true) {
                showToast(
                    "error",
                    "The status of this treatment is reviewed, so labs can't be copied"
                );
                setModalLoader(false);
                return;
            }

            const previousVitals = treatment.vitals;
            if (previousVitals.length <= 0) {
                setModalLoader(false);

                showToast(
                    "error",
                    "There are no vitals from the previous treatment to copy from"
                );
                return;
            }
            const validatedItems = previousVitals.map((item) => {
                return {
                    HR: item.HR,
                    BP: item.BP,
                    T: item.T,
                    W: item.W,
                    RR: item.RR,
                    SPO2: item.SPO2,
                    time: item.time,
                };
            });

            handleCellChange(treatment._id, "vitals", validatedItems, "vitals");
            setModalLoader(false);

            showToast("success", "Successfully copied labs from previous treatment");
            if(from==='emr'){
                setAllTreatments((prevState) => {
                    return prevState.map((note) => {
                      const updatedRelatedOrders = note.relatedOrders.map((order) => {
                        const updatedOrder = prevState
                          .flatMap((prevNote) => prevNote.relatedOrders) 
                          .find((existingOrder) => existingOrder._id === order._id);
                
                        if (updatedOrder) {
                          return {
                            ...order,
                            vitals: [...validatedItems] , 
                          };
                        }
                        return order; 
                      });
                
                      return {
                        ...note,
                        relatedOrders: updatedRelatedOrders,
                      };
                    });
                  });
            }
            else{
                
                setAllTreatments((prevState) =>
                    prevState.map((order) => {
                        if (order._id === treatment._id) {
                            return { ...order, vitals: [...validatedItems] };
                        } else {
                            return order; 
                        }
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching previous treatments:", error);
            setModalLoader(false);

            showToast("error", "Failed to fetch previous treatments");
        }
    };

    const onClose = () => {
        setShowModal(false)
        setModalType(null)
        
    }
    const placeOrder = (types) => {
        fetch(`/shopify/placeorder/${treatment._id}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ types }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                onClose();
                toast.success("Order placed successfully");
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error(`Error placing order: ${error.message}`);
            });
    };

    return (
        <Dialog
            open={showModal}
            onClose={() => {
                onClose();
                setSearchTerm("");
                if(type==='Vitals'){
                    handleUpdateVitals()
                }
            }}
            maxWidth={type === "Notes" ? false : "md"}  // Use false for custom width, "md" for predefined maxWidth
            PaperProps={{
                style: {
                    width: type === "Notes" ? '80vw' : false,  // Custom width for "Notes", default for others
                    padding: type === "Notes" ? '20px' : 'auto'
                }
            }}
            fullWidth
        >
            <div className="flex justify-between items-center">
                <DialogTitle>
                    <span className={styles["proctitleStyling"]}>
                        {/* <IconButton onClick={handlePrevious}>
                            <ChevronLeft />
                        </IconButton> */}
                        {type === "Labs" ? "Update Labs" : type === "Procedures" ? "Update Procedures" : type === "Dispensed" ? "Update Dispensed" : type === "Notes" ? "Treatment Notes" : type === "Vitals" ? "Update Vitals Items" : null}
                        {/* <IconButton onClick={handleNext}>
                            <ChevronRight />
                        </IconButton> */}
                    </span>
                    {(type === "Labs" || (type === "Procedures" || type === "Dispensed")) && <Tooltip
                        title={
                            <h1 className="text-sm">
                                Copy Procedures From Previous Treatment
                            </h1>
                        }
                        placement="right"
                    >
                        <IconButton
                            onClick={() => {
                                if (treatment.review && from !== 'fdv') {
                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                } else { handleCopyProceduresFromPreviousTreatment() }
                            }}
                            sx={{ ml: "10px" }}
                        >
                            <ContentCopyRounded sx={{ fontSize: 30 }} />
                        </IconButton>
                    </Tooltip>}
                </DialogTitle>


            </div>
            {type === "Vitals" &&
                <div className="flex items-center justify-between">
                    <div className="activeStylingD w-fit">{patientName}</div>
                    <div className="flex w-full justify-end">
                        <button className="newVitalBtn" onClick={addNewVitalsRow}>
                            Add New Vitals
                        </button>
                    </div>
                </div>}
            {(type === "Labs" || (type === "Procedures" || type === "Dispensed")) &&
                <>
                    {modalLoader && (
                        <div className="loader-backdrop">
                            <BounceLoader color="#000" />
                        </div>
                    )}

                    <div className="px-5">
                        {toast && (
                            <CustomToast
                                message={toast.message}
                                variant={toast.variant}
                                customStyles={{ bottom: "40px" }}
                            />
                        )}
                        <div className="activeStylingD">{treatment?.userProcedures?.patientName}</div>
                        <div>
                            <input
                                placeholder={type === "Labs" ? "Search Labs" : type === "Procedures" ? "Search Procedures" : type === "Dispensed" ? "Search Dispensed" : null}
                                className="procsearchInput"
                                fullWidth
                                margin="normal"
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="procactiveStyling">
                            {type === "Labs" ? (
                                <>
                                    <strong>Active Labs:</strong> {getCheckedLabsSummary()}
                                </>
                            ) : type === "Procedures" ? (
                                <>
                                    <strong>Active Procedures:</strong> {getActiveProceduresSummary()}
                                </>
                            ) : type === "Dispensed" ? (
                                <>
                                    <strong>Active Dispensed Items:</strong> {getActiveDispensedItemsSummary()}
                                </>
                            ) : null}

                        </div>
                        <div className="outer-procedures-container">

                            <div className="procedures-container inner-procedures-container" ref={containerRef} >
                                {
                                    filteredItems.map((item) => {
                                        return (
                                            <div key={type === "Procedures" && item.procedure_name} className="procedure-row flex">
                                                {/* Item Name Section */}
                                                <div className="procedure-name w-3/5">

                                                    {type === "Dispensed" && item.editItemName ? (
                                                        <>
                                                            <div className="flex">
                                                                {item.procedure_name ? (
                                                                    item.procedure_name
                                                                ) : (
                                                                    <input
                                                                        className="dispensedTextInput"
                                                                        type="text"
                                                                        placeholder="Name"
                                                                        // value={procedure.procedure_name || ""}
                                                                        onBlur={(e) =>
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "procedure_name",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                )}

                                                                <input
                                                                    className="dispensedTextInput ml-[20px]"
                                                                    type="text"
                                                                    placeholder="Dosage"
                                                                    // value={procedure.selectedField || ""}
                                                                    onBlur={(e) => {
                                                                        if (treatment.review && from !== 'fdv') {
                                                                            return message.warning("This treatment is finalized so can't make any changes in it")
                                                                        }
                                                                        else {
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "selectedField",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    }
                                                                    }
                                                                    fullWidth
                                                                />
                                                            </div>
                                                        </>
                                                    ) : type === "Procedures" && item.editItemName ? (
                                                        <input
                                                            className="dispensedTextInput"
                                                            type="text"
                                                            value={item.name || ""}
                                                            placeholder="Name"
                                                            onChange={
                                                                (e) =>
                                                                    handleChange(item, item.name, "name", e.target.value) // Update the name when changed
                                                            }
                                                            fullWidth
                                                        />
                                                    ) :
                                                        (type === "Labs" ? <p> {item.test_name}</p> : type === "Procedures" ? <p> {item.procedure_name}</p> : type === "Dispensed" ? <p> {item.name}</p> : "")}


                                                </div>
                                                {/* Checkbox Section */}
                                                <div className="procedure-checkbox w-1/5 flex justify-center items-center">

                                                    {("checked" in item || "selectedField" in item) && (

                                                        <Checkbox
                                                            checked={item.checked || item.quantity > 0 ? true : false}
                                                            onChange={(e) => {
                                                                if (treatment.review && from !== 'fdv') {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                }
                                                                else {
                                                                    if (e.target.checked) {
                                                                        if (type === "Procedures" || type === "Dispensed") {
                                                                            handleChange(
                                                                                item,
                                                                                type === "Procedures" ? item.procedure_name : type === "Dispensed" ? item.name : null,
                                                                                "checked",
                                                                                e.target.checked
                                                                            );
                                                                            handleChange(
                                                                                item,
                                                                                type === "Procedures" ? item.procedure_name : type === "Dispensed" ? item.name : null,
                                                                                "selectedField",
                                                                                item.dosages[0]
                                                                            )
                                                                        }
                                                                        else if (type === "Labs") {
                                                                            handleChange(
                                                                                item,
                                                                                item.test_name,
                                                                                "checked",
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    }
                                                                    else {
                                                                        if (type === "Procedures" || type === "Dispensed") {
                                                                            handleChange(
                                                                                item,
                                                                                type === "Procedures" ? item.procedure_name : type === "Dispensed" ? item.name : null,
                                                                                "checked",
                                                                                e.target.checked
                                                                            );
                                                                            handleChange(
                                                                                item,
                                                                                type === "Procedures" ? item.procedure_name : type === "Dispensed" ? item.name : null,
                                                                                "selectedField",
                                                                                ''
                                                                            )
                                                                        }
                                                                        else if (type === "Labs") {
                                                                            handleChange(
                                                                                item,
                                                                                item.test_name,
                                                                                "checked",
                                                                                e.target.checked
                                                                            )
                                                                        }

                                                                    }
                                                                }
                                                            }}
                                                        // disabled={("selectedField" in procedure)}
                                                        />
                                                    )}
                                                </div>
                                                {/* Custom Item Section */}
                                                <div className="procedure-details w-1/5 flex justify-start items-center">
                                                    {item.custom && (
                                                        <IconButton
                                                            onClick={() => handleDeleteProcedure(item._id)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {/* Dosages Section */}
                                                    {item.dosages && item.dosages.length > 0 && (
                                                        <div className="procedure-dosage my-auto">
                                                            <Select
                                                                className="dosageSelect"
                                                                value={item.selectedField ? item.selectedField : item.checked ? item.dosages[0] : ""}
                                                                onChange={(e) => {
                                                                    if (treatment.review && from !== 'fdv') {
                                                                        return message.warning("This treatment is finalized so can't make any changes in it")
                                                                    }
                                                                    else {
                                                                        if (type === 'Procedures') {
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "selectedField",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        else if (type === 'Dispensed') {
                                                                            handleChange(
                                                                                item,
                                                                                item.name,
                                                                                "selectedField",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                                }
                                                                fullWidth
                                                            >
                                                                <MenuItem key="empty-option" value="">
                                                                    Empty
                                                                </MenuItem>
                                                                {item.dosages.map((dosage, idx) => (
                                                                    <MenuItem key={idx} value={dosage}>
                                                                        {dosage}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                    {/* Custom Dosage Section */}
                                                    {"customDosage" in item && (
                                                        <div className="procedure-note my-auto pr-2">
                                                            <input
                                                                className="note-input"
                                                                value={item.customDosage || ""}
                                                                onChange={(e) => {
                                                                    if (treatment.review && from !== 'fdv') {
                                                                        return message.warning("This treatment is finalized so can't make any changes in it")
                                                                    } else {
                                                                        handleChange(
                                                                            item,
                                                                            item.procedure_name,
                                                                            "customDosage",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                }
                                                                }
                                                                fullWidth
                                                                placeholder="Dosage"
                                                            />
                                                        </div>
                                                    )}
                                                    {/* Note Section */}
                                                    {"note" in item && (
                                                        <div className="procedure-note my-auto pr-2">
                                                            {item.showNoteInputRow ||
                                                                (item.note && item.note.trim() !== "") ? (
                                                                <input
                                                                    className="note-input"
                                                                    value={item.note || ""}
                                                                    onChange={(e) => {
                                                                        if (treatment.review && from !== 'fdv') {
                                                                            return message.warning("This treatment is finalized so can't make any changes in it")
                                                                        } else {
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "note",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    }
                                                                    }
                                                                    fullWidth
                                                                />
                                                            ) : (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (type === "Procedures") {
                                                                            toggleNoteInputRow(item.procedure_name)
                                                                        }
                                                                        else if (type === "Dispensed") (
                                                                            toggleNoteInputRow(item.name)
                                                                        )
                                                                    }
                                                                    }
                                                                >
                                                                    <NotesIcon />
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    )}

                                                    {type === "Dispensed" && <div className="divTableCell contentFitthreeC">
                                                        <input
                                                            className="dispensedNumberInput"
                                                            type="number"
                                                            value={item.quantity || 0}
                                                            onChange={(e) => {
                                                                if (treatment.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                } else {
                                                                    handleChange(item, item.name, "quantity", e.target.value)
                                                                }
                                                            }
                                                            }
                                                            fullWidth
                                                        />
                                                    </div>}
                                                    {!item.isNew && type === "Dispensed" ? ( // Only render the button if the item is not new
                                                        !dispensedItemsArray.includes(item.name) ? ( // Check if the item name is not in the specified list
                                                            <IconButton onClick={() => handleRemoveItem(item)}>
                                                                <RemoveCircleRounded className="text-red-500" />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton onClick={() => {
                                                                if (treatment.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                } else { handleAddItem(item.name) }
                                                            }}>
                                                                <AddCircleRounded className="text-green-500" />
                                                            </IconButton>
                                                        )
                                                    ) : (
                                                        <div className="w-[30px]"></div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                        {(type === "Procedures" || type === "Dispensed") && <DialogActions>
                         {!treatment.review && <button
                                className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
                                onClick={handleAddEmptyRow}
                            >
                                Add Custom Item
                            </button>}
                            <button
                                className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
                                onClick={onClose}
                            >
                                Finish
                            </button>
                        </DialogActions>}
                        
                        
                    </div>
                    
                </>
            }
            {type === "Notes" && <TreatmentNotes
                treatment={treatment}
                isTreatmentNoteEditing={isTreatmentNoteEditing.current}
                setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
            />}
            {type === 'Vitals' && <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow className="rowStyling">
                            <TableCell>BP</TableCell>
                            <TableCell>HR</TableCell>
                            <TableCell>RR</TableCell>
                            <TableCell>SPO2</TableCell>
                            <TableCell>T</TableCell>
                            <TableCell>W</TableCell>
                            <TableCell>Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {treatment.vitals.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="text"
                                        value={item.BP}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "BP", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.HR}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "HR", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.RR}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "RR", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.SPO2}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "SPO2", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.T}
                                        onChange={(e) => handleVitalsChange(index, "T", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.W}
                                        onChange={(e) => handleVitalsChange(index, "W", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TimePicker
                                        className="datetimeStling"
                                        use12Hours
                                        format="h:mm a"
                                        value={item.time ? moment(item.time) : null}
                                        onChange={(time) => handleTimeChange(index, time)}
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>}
            {type === "Vitals" && <DialogActions>
                            <button className="btn-color py-2 px-14" onClick={()=>handleUpdateVitals()}>
                            Finish
                            </button>
                        </DialogActions>}
        </Dialog >
        // <>
        //     {type && <p>{type}</p>}
        //     {treatment && <p>{JSON.stringify(treatment)}</p>}
        // </>
    );
};

export default LPDPopup;
