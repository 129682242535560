import React from "react";
import { Button } from "antd";

const Header = ({
  styles
}) => {
 
  return (
    <header className="bg-white shadow p-4 flex flex-col md:flex-row justify-between items-center">
      <div className="flex items-center space-x-4 mb-4 md:mb-0">
        <h1 className="text-2xl font-bold mb-0">EMR</h1>
        
      </div>
      
    </header>
  );
};

export default Header;
