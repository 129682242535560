import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Button,
  Modal,
  Typography,
  Space,
  message,
  Divider,
  Spin,
} from "antd";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Button as MuiButton } from "@mui/material";
import classNames from "classnames";
import styles from "./Messages.module.css"; // Import CSS module
import UpdatePatient from "./UpdatePatient";
import { debounce } from "lodash"; // Import lodash for debouncing

const { TextArea } = Input;
const { Text, Title } = Typography;

const formatDateTime = (dateTime) => {
  const date = new Date(dateTime);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const Messages = ({ patient, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [recipientNumber, setRecipientNumber] = useState(
    patient?.phoneNumbers?.find((phone) => phone.isDefault)?.number || ""
  );

  const fetchConversation = useCallback(async () => {
    if (!recipientNumber) return;

    try {
      const response = await axios.get("/get-conversation", {
        params: { phoneNumber: recipientNumber },
      });
      if (response.data && response.data.messages) {
        const sortedMessages = response.data.messages.sort(
          (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
        );
        setMessages(sortedMessages);
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
      message.error("Failed to fetch conversation");
    }
  }, [recipientNumber]);

  useEffect(() => {
    fetchConversation();
    const intervalId = setInterval(fetchConversation, 5000); // Poll every 5 seconds
    return () => clearInterval(intervalId);
  }, [fetchConversation]);

  // Debounced message send handler
  const debouncedSendMessage = useCallback(
    debounce(async () => {
      if (!messageText.trim() || !recipientNumber) {
        message.warning("Please fill in both message and recipient number.");
        return;
      }

      setIsSending(true);
      try {
        await axios.post("/send-sms", {
          to: recipientNumber,
          text: messageText,
        });
        setMessageText("");
        message.success("Message sent successfully");
        fetchConversation();
      } catch (error) {
        console.error("Error sending message:", error);
        message.error("Failed to send message");
      } finally {
        setIsSending(false);
      }
    }, 500), // Debounce delay of 500ms
    [messageText, recipientNumber, fetchConversation]
  );

  const handleSendMessage = () => {
    debouncedSendMessage();
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      width={600}
      bodyStyle={{ padding: 0 }}
    >
      <div className={styles.header}>
        <Title level={4} className={styles.title}>
          Conversation with {patient["Full Name"]}
        </Title>
        <Divider />
        <div className={styles.infoContainer}>
          <Text type="primary">Phone: {recipientNumber}</Text>
          <UpdatePatient patient={patient} />
        </div>
      </div>
      <div className={styles.messagesContainer}>
        {messages.map((item, index) => (
          <div
            key={index}
            className={classNames(styles.messageItem, {
              [styles.inbound]: item.direction === "inbound",
              [styles.outbound]: item.direction === "outbound",
            })}
          >
            <div className={styles.messageBubble}>
              <Text strong className={styles.messageType}>
                {item.direction === "inbound" ? "Received" : "Sent"} ({item.type})
              </Text>
              <Text className={styles.messageText}>{item.message}</Text>
              <Text type="secondary" className={styles.timestamp}>
                {formatDateTime(item.dateTime)}
              </Text>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <TextArea
          rows={2}
          placeholder="Type your message..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          className={styles.textArea}
        />
        <MuiButton
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          disabled={isSending}
          className={styles.sendButton}
        >
          {isSending ? (
            <Spin
              indicator={<LoadingOutlined style={{ color: "white" }} spin />}
            />
          ) : (
            <>
              <SendOutlined style={{ marginRight: "8px" }} />
              Send
            </>
          )}
        </MuiButton>
      </div>
    </Modal>
  );
};

export default Messages;
